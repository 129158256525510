import './serviceDetail.css';
import Cataract from '../../images/cataract1.jpg';
import Cataract2 from '../../images/cataract2.jpg';
import { useLocation } from 'react-router-dom';
import ServcieData from '../ServiceData/ServiceData';
import { Link } from 'react-router-dom';
import React, { useLayoutEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { MetaTags } from 'react-meta-tags';

const ServiceDetail = () => {

        useLayoutEffect(() => {
            window.scrollTo(0, 0)
        });

        const search = useLocation();
        const Path = search.pathname;

        const [serviceInfo, setServiceInfo] = useState(ServcieData);
        const ServiceDataFinal = serviceInfo.find(e => e.Slugs == Path)

        console.log(ServiceDataFinal.ServiceImage)
    const FImage = ServiceDataFinal.ServiceImage


    return (
        <div>

            <MetaTags>
                <title>{ServiceDataFinal.metatitle}</title>
                <meta title={ServiceDataFinal.metatitle} />
                <meta
                    name="description"
                    content={ServiceDataFinal.metaDes}
                />
                <meta
                    name="keywords"
                    content="Best Eye Hospital In Khar Mumbai,Cataract surgery In Khar Mumbai,LASIK Surgery in Khar Mumbai,Retina Surgery in Khar Mumbai,Cornea Specialist in Khar Mumbai,Best Eye Doctor in Khar Mumbai,Laser Eye Surgery In Khar Mumbai "
                />
                <link rel="canonical" href={ServiceDataFinal.canonical} />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content={ServiceDataFinal.metatitle} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://eyelifehospital.com/" />
                <meta property="og:description" content={ServiceDataFinal.metaDes} />
                <meta property="og:image" content="https://eyelifehospital.com/static/media/logo-footer.0798714161edb8b2d5b9.png" />
            </MetaTags>

            {/* ServiceDetail Home */}
            <div className='serviceDetailHome'>
                <h2>{ServiceDataFinal.head}</h2>
            </div>

            {/* service Detail */}
            <div className='serviceDetailSection'>
                <div className='Service-Image-Con'>
                    <div className='detailImg'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                FImage.map((ele) => {
                                    return (
                                        <>
                                            <SwiperSlide>
                                                <div className='Service-Image01'>
                                                    <img src={ele.Image01} />
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                            {/* <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Service-Image01'>
                                    <img src={ServiceDataFinal.image} />
                                </div>
                            </SwiperSlide> */}
                        </Swiper>
                    </div>
                </div>
                <div className='detailText'>
                    <h1></h1>
                    <p>{ServiceDataFinal.TitileTitile}</p>
                    <h6>{ServiceDataFinal.SerivcePara01head}</h6>
                    <p>{ServiceDataFinal.SerivcePara01}</p>
                </div>
            </div>

            <div className='container'>
                <div className='Service-Detials-Para-con'>
                    <h6>{ServiceDataFinal.SerivcePara02head}</h6>
                    <p>{ServiceDataFinal.SerivcePara02}</p>
                    <h6>{ServiceDataFinal.SerivcePara03head}</h6>
                    <p>{ServiceDataFinal.SerivcePara03}</p>
                    <p>{ServiceDataFinal.SerivcePara04}</p>
                    <p>{ServiceDataFinal.SerivcePara05}</p>
                    <p>{ServiceDataFinal.SerivcePara06}</p>
                    <p>{ServiceDataFinal.SerivcePara07}</p>
                    <p>{ServiceDataFinal.SerivcePara08}</p>
                    <p>{ServiceDataFinal.SerivcePara09}</p>
                    <p>{ServiceDataFinal.SerivcePara10}</p>
                    <p>{ServiceDataFinal.SerivcePara11}</p>
                </div>
            </div>

            <div className='container'>
                <div className='Service-Point-con'>

                    <h5>{ServiceDataFinal.PointHead001}</h5>
                    <p>{ServiceDataFinal.PointHeadPoint01}</p>
                    <h6>{ServiceDataFinal.SerivcePara02head}</h6>
                    <p>{ServiceDataFinal.PointHeadPoint02}</p>
                    <h6>{ServiceDataFinal.SerivcePara03head}</h6>
                    <p>{ServiceDataFinal.PointHeadPoint03}</p>
                    <p>{ServiceDataFinal.PointHeadPoint04}</p>
                    <p>{ServiceDataFinal.PointHeadPoint05}</p>
                    <p>{ServiceDataFinal.PointHeadPoint06}</p>
                    <p>{ServiceDataFinal.PointHeadPoint07}</p>
                    <p>{ServiceDataFinal.PointHeadPoint08}</p>
                    <p>{ServiceDataFinal.PointHeadPoint09}</p>
                    <p>{ServiceDataFinal.PointHeadPoint10}</p>
                </div>
            </div>

            <div className='container'>
                <div className='Service-Para-Point-con'>

                    <h6>{ServiceDataFinal.point01}</h6>
                    <p>{ServiceDataFinal.Parapoint01}</p>
                    <p>{ServiceDataFinal.Parapoint11}</p>

                    <h6>{ServiceDataFinal.point02}</h6>
                    <p>{ServiceDataFinal.Parapoint02}</p>
                    <p>{ServiceDataFinal.Parapoint22}</p>
                    <p>{ServiceDataFinal.Parapoint23}</p>
                    <p>{ServiceDataFinal.Parapoint24}</p>

                    <h6>{ServiceDataFinal.point03}</h6>
                    <p>{ServiceDataFinal.Parapoint03}</p>

                    <h6>{ServiceDataFinal.point04}</h6>
                    <p>{ServiceDataFinal.Parapoint04}</p>

                    <h6>{ServiceDataFinal.point05}</h6>
                    <p>{ServiceDataFinal.Parapoint05}</p>

                    <h6>{ServiceDataFinal.point06}</h6>
                    <p>{ServiceDataFinal.Parapoint06}</p>

                    <h6>{ServiceDataFinal.point07}</h6>
                    <p>{ServiceDataFinal.Parapoint07}</p>

                    <h6>{ServiceDataFinal.point08}</h6>
                    <p>{ServiceDataFinal.Parapoint08}</p>

                    <h6>{ServiceDataFinal.point09}</h6>
                    <p>{ServiceDataFinal.Parapoint09}</p>

                    <h6>{ServiceDataFinal.point10}</h6>
                    <p>{ServiceDataFinal.Parapoint10}</p>

                    <h6>{ServiceDataFinal.point11}</h6>
                    <p>{ServiceDataFinal.Parapoint101}</p>

                    <h6>{ServiceDataFinal.point12}</h6>
                    <p>{ServiceDataFinal.Parapoint121}</p>
                    <p>{ServiceDataFinal.Parapoint122}</p>

                    <h6>{ServiceDataFinal.point13}</h6>
                    <p>{ServiceDataFinal.Parapoint131}</p>

                    <h6>{ServiceDataFinal.point14}</h6>
                    <p>{ServiceDataFinal.Parapoint141}</p>

                    <h6>{ServiceDataFinal.point15}</h6>
                    <p>{ServiceDataFinal.Parapoint151}</p>

                    <h6>{ServiceDataFinal.point16}</h6>
                    <p>{ServiceDataFinal.Parapoint161}</p>

                </div>

            </div>
            <div className='container'>
                <Link to='/eye-treatment-services-in-khar'>
                    <div className='ServiceDeatilsbtn d-flex justify-content-center align-item-center mt-2'>
                        <button>Click here for more services</button>
                    </div>
                </Link>
            </div>

        </div>



    )
}

export default ServiceDetail;