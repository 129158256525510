import React from 'react';
import './CallIcon.css';
import { Link } from 'react-router-dom';

const CallIcon = () => {
    return (
        <div>
            <div className='callIncon'>
                <div class="icon-bar">
                    <a href="tel:+919967262640" class="facebook"><i class="fa fa-phone" aria-hidden="true"></i></a>
                    <a href="https://wa.me/+919967262640?text=" target="_blank" class="twitter"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                    <a href="mailto: eyelifehospital@gmail.com" class="google"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
                    {/* <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>
                    <a href="#" class="youtube"><i class="fa fa-youtube"></i></a> */}
                </div>

            </div>
        </div>
    )
}

export default CallIcon;