
import '../DoctorDetail/doctorDetail.css';
import doctorImg from '../../images/sushmita.png';
import React, { useState, useLayoutEffect } from 'react';
import DrSushmitaImage from "../../images/IMG_8229.jpg";

const DrSushmita = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <div>
            {/* banner */}
            <div className='doctorHome'>
                <h2>Dr. Sushmita Shah</h2>
            </div>

            {/* Docotr Details */}
            <div className='container'>

                <div className='doctorDetail-con'>
                    <div className='doctorImage'>
                        <img src={DrSushmitaImage} alt="Dr. Sushmita Shah" />
                    </div>
                    <div className='doctorText'>
                        <h2>Dr. Sushmita Shah </h2>
                        <h6>Dr. Sushmita Shah is Director, Eye Life and Senior Consultant in Cataract, Lasik, Cornea, Dry Eye, Ocular Surface, and Keratoconus Services. She specialises in corneal surgeries such as corneal transplant beside being an expert in cataract surgery and refractive surgery for removal of spectacle number such as LASIK. </h6>
                        <h6>She is trained from some of the best academic institutes in India and Abroad. Following her Ophthalmology training from the prestigious eye institute, Sankara Nethralaya, in Chennai, she has done Cornea and Anterior Segment fellowship at L V Prasad Eye Institute in Hyderabad. Dr. Sushmita Shah has specialised in cornea and refractive surgery from Mass Eye and Ear at the esteemed Harvard Medical School in USA. </h6>
                        <h6>She has been a Consultant in Cataract and Retina Services at L V Prasad Eye Institute and Lilavati Hospital, Bandra.</h6>
                    </div>
                    <div className='doctorText'>
                        <h5>She is an expert in:</h5>
                        <ul>
                            <li>Lamellar corneal transplants – DSEK, DALK</li>
                            <li>Keratoconus management – Collagen Cross Linking, INTACS, Specialized contact lenses</li>
                            <li>Anterior Segment Reconstruction – Iridodialysis repair, Pupilloplasty</li>
                            <li>Boston keratoprosthesis, Temporary keratoprosthesis</li>
                            <li>Ocular surface Reconstruction – SLET, Amniotic membrane grafting, Tenonplasty, Pterygium</li>
                            <li>Penetrating keratoplasty – Optical, Therapeutic</li>
                            <li>Microbial Keratitis management including microbiology</li>
                            <li>Cataract surgery in compromised cornea and ocular surface</li>
                            <li>Refractive surgery – LASIK, PRK</li>
                            <li>Management of Ocular Trauma</li>
                            <li>Management of Dry eyes, Computer Vision Syndrome</li>
                            <li>Medical management of corneal and ocular surface diseases</li>
                        </ul>
                        <p>She has more than 21 peer-reviewed publications in national and international journals through 2023. She is reviewer for Indian Journal of Ophthalmology, Journal of ocular pharmacology and therapeutics, and Journal of Clinical Ophthalmology and Research. She is on the prestigious editorial board of the Indian Journal of Ophthalmology.</p>
                        <p>She is a member of All India Ophthalmological Society, Bombay Ophthalmologist’s Association, Maharashtra Ophthalmological Society, Cornea Society, India, and Ophthalmological Society of West Bengal.</p>
                        <h5>She has numerous awards to her credit including:</h5>
                        <ul>
                            <li>Awarded Research Grant by Department of Biotechnology, Government of India for Collaborative Research project with IIT, Delhi in 2012.</li>
                            <li>Awarded Best Free Paper Award at Keracon 2014, Annual Meeting of Cornea Society of India, held at Mahabalipuram, Chennai.</li>
                            <li>Awarded Dr M C Luthra Gold Medal for Best Paper at Uttaraeyecon 2014, Annual Meeting of Uttarakhand State Ophthalmological Society.</li>
                            <li>Awarded Second Prize for Young ophthamologist’s for Scientific Video on “Management of Pterygium” at the Annual Meeting of the European Society of Cataract and Refractive Surgeons, at Stockholm, Sweden in September 2007</li>
                            <li>Awarded “Honorable Mention” for scientific poster on “Intracameralperfluoropropane gas for the treatment of Acute Cornea Hydrops” at the World Cornea Congress, at Washington DC, April 2005.</li>
                            <li>Awarded International Travel Grant by Sir Dorabji Tata Trust, Mumbai for poster presentation at World Cornea Congress, 2005 at Washington DC.</li>
                            <li>Awarded Second Prize for Innovation for Scientific Video on “Intracameralperfluoropropane gas for the treatment of Acute Cornea Hydrops” at the Annual Meeting of the European Society of Cataract and Refractive Surgeons, September 2005.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrSushmita