import './contact.css';
import React, { useState, useLayoutEffect } from 'react';

const Contact = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <div>
            {/* contact home */}
            <div className='contactHome'>
                <div className='contactHomeInner'>
                    <h1>Contact Us</h1>
                    {/* <h6>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae, reiciendis</h6> */}
                </div>
            </div>

            {/* Contact Boxes */}
            <div className='contactBoxes'>
                <div className='row'>
                    <div className='col-md-4 '>
                        <div className='contactBox d-flex justify-content-center align-items-center'>
                            <a href="tel:9967262640">
                                <div className='contactBoxIcon'>
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                </div>
                            </a>
                            <div className='contactBoxText'>
                                <h4>Call us Here</h4>
                                {/* <a href="tel:022-26002640">022-26002640</a> */}
                                <a href="tel:9967262640">9967262640</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 '>
                        <div className='contactBox d-flex justify-content-center align-items-center'>
                           <a href="mailto: eyelifehospital@gmail.com">
                            <div className='contactBoxIcon'>
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                           </a>
                            <div className='contactBoxText'>
                                <h4>Mail us Here</h4>
                                <a href="mailto: eyelifehospital@gmail.com">eyelifehospital@gmail.com</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='contactBox  d-flex justify-content-center align-items-center'>
                            <a href='https://goo.gl/maps/bPAUptqmRtVpMPwS6' target='blank'>
                                <div className='contactBoxIcon'>
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                            </a>
                            <div className='contactBoxText'>
                                <h4>Visit us</h4>
                                <a href='https://goo.gl/maps/bPAUptqmRtVpMPwS6' target='blank'><p>Click for location</p> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* contact map */}
            <div className='contactMap'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.749110991129!2d72.83225971482187!3d19.074765387088632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90eae6d0c89%3A0x7700a5d0be05b482!2sEye%20Life%20Hospital!5e0!3m2!1sen!2sin!4v1663792798805!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" />
            </div>

            {/* contact form */}
            <div className='contactFrom mt-5 mt-lg-0 d-flex align-items-stretch'>
                <form action="php_file/mail.php" method="post" role="form" className="php-email-form">
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label for="name">Your Name</label>
                            <input type="text" name="name" className="form-control" id="contName" required />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="name">Your Email</label>
                            <input type="email" className="form-control" name="email" id="contEmail" required />
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="name">Contact Number</label>
                        <input type="text" className="form-control" name="subject" id="contSubject" required />
                    </div>
                    <div className="form-group">
                        <label for="name">Query</label>
                        <textarea className="form-control" name="message" id="contMessage" rows="6" required></textarea>
                    </div>
                    <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">
                            Your message has been sent. Thank you!
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="submit" id="sendcontactus" onClick="sendContact();">
                            Send Message
                        </button>
                    </div>
                </form>
            </div>



        </div>
    )
}

export default Contact;