import Cornea from "../../images/Services/Cornea/Corneal edema.jpg";
import Cataract from "../../images/Services/cataract 1.jpg";
import Lasik from "../../images/lasik.jpg";
import LasikDustbin from "../../images/lasik-throw-glass.jpg";
import Retina from "../../images/Services/Retina/Retinal Detachment.jpg";
import DryEye from "../../images/Dry eyes.jpg";
import Keratoconus from "../../images/Services/Keratoconus/Keratoconus INTACS.jpg";
import Glaucoma from "../../images/Glaucoma.jpg";
import uveitis from "../../images/Services/uveitis.jpg";
import Squint from "../../images/Squint.jpg";
import Oculoplasty from "../../images/Services/Oculoplasty.jpg";
import neutoOpthelmo from "../../images/neuro-opthelmo.jpg";
import PedOphthalmolgy from "../../images/Services/Paediatric ophthalmology cataract.jpg";
import lens from "../../images/lens.jpg";
import Trauma from "../../images/Services/Trauma/Anteriror segment reconstruction.jpg";

import LowVisionAids from '../../images/low-vision.jpg';


/////////

import Retina01 from "../../images/Services/Retina/ARMD 1.jpg";
import Retina02 from "../../images/Services/Retina/ARMD 2.jpg";
import Retina03 from "../../images/Services/Retina/Diabetic retinopathy.jpg";
import Retina04 from "../../images/Services/Retina/Hypertensice retinopathy.jpg";
import Retina05 from "../../images/Services/Retina/Retinal Detachment.jpg";
import Cornea01 from "../../images/Services/Cornea/Corneal edema 2.jpg";
import Cornea02 from "../../images/Services/Cornea/Corneal edema.jpg";
import Cornea03 from "../../images/Services/Cornea/Corneal ulder.jpg";
import Cornea04 from "../../images/Services/Cornea/Endothelial keratoplasty.jpg";
import Cornea05 from "../../images/Services/Cornea/Pterygium 2.jpg";
import Cornea06 from "../../images/Services/Cornea/Pterygium.jpg";
import Cornea07 from "../../images/Services/Cornea/Red eye.jpg";
import DryEye01 from "../../images/Services/Dry eye ocular surface/Limbal stem cell deficiency.jpg";
import DryEye02 from "../../images/Services/Dry eye ocular surface/Limbal stem cell transplant.jpg";
import DryEye03 from "../../images/Services/Dry eye ocular surface/Ocular cicatricial pemphigoid.jpg";
import DryEye04 from "../../images/Services/Dry eye ocular surface/Ocular surface tumour 1.jpg";
import DryEye05 from "../../images/Services/Dry eye ocular surface/Ocular surface tumour 2.jpg";
import DryEye06 from "../../images/Services/Dry eye ocular surface/Ocular surface tumour 3.jpg";
import DryEye07 from "../../images/Services/Dry eye ocular surface/Stevens Johnson syndrome.jpg";
import Keratoconus01 from "../../images/Services/Keratoconus/Keratoconus DALK.jpg";
import Keratoconus02 from "../../images/Services/Keratoconus/Keratoconus INTACS.jpg";
import Trauma01 from "../../images/Services/Trauma/Anterior segment reconstruction b2.jpg";
import Trauma02 from "../../images/Services/Trauma/Anteriror segment reconstruction.jpg";
import Trauma03 from "../../images/Services/Trauma/Chemical injury.jpg";
import Trauma04 from "../../images/Services/Trauma/Corneal tear 2.jpg";
import Trauma05 from "../../images/Services/Trauma/Corneal tear.jpg";

/////////////////////////////////
import Ncatract01 from "../../images/new service/Contact Lens.jpg";
import ContactLens01 from "../../images/new service/Contact Lens.jpg";
import lasik01 from "../../images/new service/Lasik 01.jpg";
import NRetina01 from "../../images/new service/Retina ERM.jpg";
import NRetina02 from "../../images/new service/Retina ERM.png";
import NRetina03 from "../../images/new service/Retina.jpeg";
import NRetina04 from "../../images/new service/Retinal Detachment.jpg";
import NRetina05 from "../../images/new service/Retinitis Pigmentosa.jpg";
import NGlaucoma01 from "../../images/new service/Glaucoma.jpg";
import NGlaucoma02 from "../../images/new service/Glaucoma.png";
import Ncatract from "../../images/new service/cartract.jpg";
import NSquint from "../../images/new service/Squint.png";
import NOculoplasty from "../../images/new service/Oculoplasty 01.jpg";
import NPaediatricOphthalmology from "../../images/new service/Paediatric ophthalmology.jpg";
import NOculoplasty01 from "../../images/new service/Oculoplasty 01.png";
import LowVisionAids01 from "../../images/new service/Low Vision Aids 01.jpg";
import LowVisionAids02 from "../../images/new service/Low Vision Aids 02.png";
import NKeratoconus01 from "../../images/new service/Keratoconus 01.jpg";





const ServcieData = [
  {
    id: 1,
    title: "Cataract",
    Slugs: "/cataract-surgery-in-khar-mumbai",
    metatitle:"Cataract surgery In Khar Mumbai | Eye Life Hospital",
    metaDes:"Experience advanced cataract surgery at Eye Life Hospital in Khar Mumbai. Regain clear vision with our expert care.",
    canonical:"https://eyelifehospital.com/cataract-surgery-in-khar-mumbai",
    head:"Cataract surgery In Khar Mumbai",
    image: Cataract,
    Titile: "Cataract Treatment in Khar Mumbai: Restoring Clarity to Your Vision",
    SerivcePara01: "Cataract is not a disease but an age-related change. As age advances, proteins such as hair and the natural crystalline lens in the eye start becoming grey to white. This clouding of the natural crystalline lens is called cataract and eventually leads to blurred vision. It can also cause changes in the prescription for glasses and other symptoms like haloes around bright lights. Very advanced cataracts can even lead to a total loss of vision.",
    SerivcePara02: "If you're searching for cataract treatment in Khar Mumbai, you have access to advanced and safe procedures that can effectively address your vision loss or cloudy vision. Cataract surgery is the recommended treatment option for cataracts. During this procedure, the cataractous lens is removed and replaced with an intraocular lens (IOL). Cataract surgery has evolved into a scientific and sophisticated procedure, ensuring a fast and painless postoperative rehabilitation period.",
    SerivcePara03: "Prior to cataract surgery, a detailed eye examination with your specialist eye doctor is essential. Cataract surgery planning involves detailed eye investigations such as A-scan and corneal measurements. Optical Biometry is the most accurate method for A-scan. Eye Life uses the advanced swept source OCT based optical biometry and schiempflug topographer for corneal measurements. These accurate measurements form the basis for accurate surgery which helps in spectacle-free vision after cataract surgery.",
    SerivcePara04: " At Eye Life, our American fellowship trained, specialist cataract surgeons, Dr. Sushmita Shah and Dr. Gaurav Shah, provide comprehensive examinations and explain all aspects of cataract surgery, including the choice of surgery and pre- and post-operative care.",
    SerivcePara05: "Cataract surgery involves the removal of the natural lens and the implantation of an intraocular lens tailored to the measurements of the eye and the visual needs of the patient. Clear vision or monofocal surgery provides excellent vision but may require reading glasses for near work after the surgery. Multifocal surgery offers spectacle-free vision for both distance and near vision. Trifocal surgery provides spectacle-free vision for distance, near, and intermediate distances. Extended depth of focus (EDOF) surgery not only offers spectacle-free vision for distance, near, and intermediate distances but also provides clear vision without rings or haloes for night driving. Toric surgery is recommended for individuals with high cylindrical numbers due to corneal astigmatism. Toric surgery is available in clear vision/monofocal, multifocal, trifocal, and EDOF options as well.",
    SerivcePara06: "Many people believe that the quality of the lens is the only important part based on which they decide the type of cataract surgery. We believe that along with the quality of the lens, the quality of the Microscope & machine used for cataract removal is equally if not more important. At Eye Life, we use the advanced Zeiss microscope with stereo co-axial illumination and Abbott machine for safe and effective cataract surgery. ",
    SerivcePara07: "At Eye Life, Mumbai, you can find experienced cataract surgeons who specialize in these advanced surgical techniques and lens options. They will guide you in choosing the most suitable treatment approach based on your unique needs and visual goals.",
    SerivcePara08: "Don't let cataracts limit your vision and quality of life. Explore the options for cataract treatment in Khar Mumbai  and consult with our reputable eye specialist at Eye Life to regain clear and sharp vision.",
    definition: "A state of the art unit, Cataract and IOL department of eye care hospital  is nationally and internationally highly acclaimed one. The department is fully equipped to do all forms of cataract surgeries with the cutting edge technologies, ensuring highest quality care.",
    ServiceImage: [
      {
        Image01: Cataract,
      },
      {
        Image01: Ncatract,
      }
    ]
  },
  {
    id: 2,
    title: "Lasik",
    Slugs: "/lasik-surgery-in-khar-mumbai",
    metatitle:"LASIK Surgery in Khar Mumbai - Eye Life Hospital",
    metaDes:"Transform your vision with LASIK surgery at Eye Life Hospital in Khar Mumbai. Achieve clear, glasses-free sight with us.",
    canonical:"https://eyelifehospital.com/lasik-surgery-in-khar-mumbai",
    head:"LASIK Surgery in Khar Mumbai",
    image: LasikDustbin,
    Titile: "LASIK is a technique used for laser vision correction or the reduction of refractive errors using laser technology. It involves the creation of a flap on the cornea using a blade, followed by laser treatment to reshape the cornea.",
    SerivcePara01head:"Femtosecond",
    SerivcePara01: "Femtosecond laser LASIK is an advanced and safer technique compared to the older blade-based method. In this procedure, a specialized laser called the Femtosecond laser is used to create the corneal flap before the laser treatment is performed. This technique offers improved precision and safety.",
    SerivcePara02head:"SMILE (Small Incision Lenticule Extraction)",
    SerivcePara02: "SMILE (Small Incision Lenticule Extraction) is the latest advancement in laser vision correction. Unlike LASIK, it does not require the creation of a corneal flap. This flapless procedure enhances the overall safety of the treatment and minimizes the weakening of corneal strength.",
    SerivcePara03head:"PRK (Photorefractive Keratectomy)",
    SerivcePara03: "PRK (Photorefractive Keratectomy) or surface ablation is performed when the cornea is too thin to create a flap. It preserves the structural strength of the cornea while correcting refractive errors.",
    SerivcePara04: "Before undergoing LASIK surgery, it is crucial to undergo a detailed eye check-up, including assessments of eye number, eye pressure, and retinal status. Additional investigations such as Scheimpflug Corneal Tomography which evaluates corneal shape, thickness, and other essential parameters, Dry Eye evaluation, and other tests are mandatory to ensure maximum safety and success following LASIK surgery.",
    SerivcePara05: "It is important to note that the goal of Laser Vision Correction is to reduce dependence on glasses or contact lenses, rather than achieving a 'zero' eye number.",
    SerivcePara06: "Complications in LASIK surgery are rare, occurring in less than 1% of cases. They may include overcorrection or undercorrection of vision, infection, ectasia (progressive corneal thinning), or haze. It is advisable to discuss these potential risks and complications with your doctor before proceeding with LASIK",
    SerivcePara06: "Dr. Sushmita Shah who has done her training from Massachusetts Eye and Ear, Harvard Medical School, USA is in charge of LASIK and refractive surgery at Eye Life. ",
    SerivcePara07: "If you are considering LASIK treatment in Khar Mumbai, it is recommended to research and choose a reputable clinic or eye center. Ensure that the chosen facility has experienced surgeons and a track record of successful LASIK procedures.",
    SerivcePara08: "Please remember that this information is provided for general knowledge and should not replace professional medical advice. Consultation with an ophthalmologist or eye specialist is necessary to receive personalized guidance regarding LASIK treatment.",
    ServiceImage: [
      {
        Image01: LasikDustbin,
      },
      {
        Image01: lasik01,
      },
    ]
  },
  {
    id: 3,
    title: "Retina",
    Slugs: "/retina-surgery-in-khar-mumbai",
    metatitle:"Retina Surgery in Khar Mumbai - Eye Life Hospital",
    metaDes:"Restore your vision with expert retina surgery at Eye Life Hospital in Khar Mumbai. Trust our specialized care for your eyes.",
    canonical:"https://eyelifehospital.com/retina-surgery-in-khar-mumbai",
    head:"Retina Surgery in Khar Mumbai",
    image: Retina,
    SerivcePara01:"Eye is like a camera. Camera has a lens in the front and a film / sensor at the back. Retina is like the film / sensor of the camera. It is found in the back part of the eye and it is the screen on which the image is formed. ",
    SerivcePara02:"Retina Diseases are managed by an eye surgeon who specializes in management of Retina Diseases. Dr. Gaurav Shah who has studied about Retinal diseases from Sankara Nethralaya, Chennai, L V Prasad Eye Institute, Hyderabad and University of Florida, USA is the Director of Retina Services at Eye Life.",
    point01: "OCT (Optical Coherence Tomography) :",
    Parapoint01: "– It is a laser-based scanning machine which can scan the retina, cornea and optic nerve to evaluate retinal diseases, corneal diseases and glaucoma. Eye Life uses a high definition imported OCT machine to give safe, non-invasive analysis of the retinal disease and glaucoma within minutes.",
    point02: "Retina Laser :",
    Parapoint02: "It is a form of light treatment given to the retina. The treatment is usually given in the sitting position in the OPD (Out Patient Department, Consulting Room). Types of retina laser include ",
    Parapoint22:"1. Macula laser to reduce edema / swelling in the macula part of the retina.",
    Parapoint23:"2. Retina laser done to prevent bleeding in the eye in Diabetic Retinopathy, BRVO (branch retinal vein occlusion), CRVO (central retinal vein occlusion), and other retinopathies.",
    Parapoint24:"3. Barrage retina laser done to strengthen the retina and reduce risk of Retina Detachment.",
    point03: "Intravitreal injection :",
    Parapoint03: "Under anaesthesia, painless injection allows medicine to quickly reach the retina and provide quick treatment. Eye Life provides various types of Intravitreal Injections such as Avastin / Bevacizumab, Zaltrap, Accentrix / Ranibizumab / Lucentis, Pagenax / Brolibizumab, Eylea / Aflibercept, Triamcinolone and Ozurdex. Our retina team will guide you to their advantages and disadvantages, specifically based on your eye condition.",
    point04: "Microscope for Retina Surgery :",
    Parapoint04: "Unlike operating microscope for cataract and other eye surgeries which focus on the front part of the eye, retina surgeries require high end microscope with a capability to view the back part of the eye. Eye Life uses an advanced German Zeiss microscope with BIOM to allow clear visualization of retina and perform safe retina surgery.",
    point05: "Vitrectomy Machine :",
    Parapoint05: "This is an advanced machine which allows robotic retina surgery with the help of delicate instruments which enter the eye to remove diseased or abnormal tissues and replace them with various types of fluid or gas. Eye Life uses a high-speed sutureless system which allows safe and stichless retina surgery.",
    point06:"Diabetic Retinopathy ",
    Parapoint06: "Diabetic Retinopathy is a retinal disease that occurs as a result of uncontrolled and long-standing diabetes. Commonest complication of people suffering from diabetes include decreased vision which can be due to diabetic cataract or retinal diseases called as Diabetic Retinopathy in which there is swelling of the retina or bleeding in front of the retina. It can manifest as mild blurring of vision in the early stages and progress to sudden vision loss and blackouts caused by bleeding in the eye in advanced cases.Diabetic retinopathy is managed by control of diabetes along with intravitreal injection, retinal laser or retina surgery based on the severity of the disease as assessed on OCT scan. If detected in time, permanent blindness can be prevented and hence it is advised that every diabetic should get retina evaluation done at least once a year or sooner if risk of vision threatening diabetic retinopathy is high as assessed by the retina specialist. ",
    point07:"BRVO (branch retinal vein occlusion) ",
    Parapoint07: "In BRVO, one of the main veins of the retina gets blocked. This leads to bleeding in the retina and swelling of the macula of the retina and results in decrease in vision. It is usually caused due to increased blood pressure with / without diabetes. It can be confirmed by OCT scan and effectively managed by intravitreal injections, laser and surgery.",
    point08:"CRVO (central retinal vein occlusion)",
    Parapoint08:"When all the veins of the retina get blocked, it is called as CRVO. Loss of vision occurs due to bleeding in the whole of the retina and severe swelling of the retina. It occurs due to increased blood pressure with / without diabetes. It is commonly treated by intravitreal injections and sometimes requires laser and surgery.",
    point09:"Retinal Detachment and Lattice Degeneration ",
    Parapoint09:"When the retina gets torn from its base, it is called as Retinal Detachment. Retinal detachment can result in sudden vision loss, with patients describing a curtain-like black out in their visual field. Retinal detachment is considered an emergency and requires surgical treatment by a qualified retina specialist. Retina Detachment occurs due to ageing. People with nearsightedness (minus number glasses) are more prone to developing retinal detachment.  Usually, floaters (grey / black particles seen against light coloured background) and flashes of light in front of the eye precede Retinal Detachment. Hence, any person having floaters or flashes of light and every person with minus spectacle number should be examined by a Retina Specialist every year. If during retina examination, Lattice Degeneration or weaking of the retina is detected, barrage retina laser can be done to strengthen the retina and reduce the risk of retinal detachment. Floaters, if obstructing vision, can be surgically removed.",
    point10:"ERM (Epi Retinal Membrane) ",
    Parapoint10:"Due to ageing or eye disease, a membrane forms over the retina and causes wrinkling of the screen of the eye (retina). This leads to damage to the retina and decrease or distortion in vision. If operated before irreversible damage occurs to the retina, surgery can help in improvement of distortion as well as quality of vision.",
    point11:"Macular Hole ",
    Parapoint101:"Macular Hole is a hole in the centre of the retina which is the most important part of the retina. Wherever a person with macular hole looks, she / he will see blurred, distorted or hazy image. Advancements in retina surgery allows repair of macular hole and good chance of visual recovery. ",
    point12:"AMD (Age related Macular Degeneration) ",
    Parapoint121:"ARMD refers to damage to the central part of the retina due to age-related factors. It typically affects both eyes, and patients may experience a black spot in their central vision. AMD is of two types – Dry and Wet. Dry AMD is more common but less vision threatening. It is managed by medicines. Wet AMD is also called as MNV (Macular Neo Vascularisation) and is vision threatening. Diagnosis is usually confirmed through an OCT scan and it also helps to differentiate between the dry and wet type of AMD. Injections administered by a qualified retina specialist are commonly used to treat ARMD.",
    Parapoint122:"Retinitis Pigmentosa and Retinal Degenerations or Dystrophies are hereditary retinal conditions associated with decreased vision. Retinal investigations such as OCT can give an idea about the vision and many times they require low vision aids to improve vision.",
    point13:"Hypertensive Retinopathy:",
    Parapoint131:"Hypertensive retinopathy occurs due to damage to the blood vessels supplying the retina. It can progress slowly and, in some cases, cause sudden vision loss due to hemorrhage. Regular eye check-ups with a retina specialist can aid in early diagnosis of this condition, while strict monitoring of blood pressure is also essential.",
    point14:"Vitreous Haemorrhage ",
    Parapoint141:" Bleeding inside the eye is called as vitreous haemorrhage. It can occur due to Diabetic Retinopathy, BRVO, CRVO, AMD, Retina Detachment / Tears. If bleeding is minimal, person will see floaters or grey to black clouds. If bleeding is extensive, it obstructs light from reaching the retina and person loses complete vision. In such cases, it is important to find out the cause of bleeding. Often, Intravitreal Injections are used to temporarily stop continuous blood accumulation in front of the retina. If the bleeding is massive or the blood clot does not get absorbed, retinal surgery / vitrectomy helps in clearing the blood and improving vision. After removing the blood, retinal laser can be done during the surgery to reduce risk of recurrence of bleeding. ",
    point15:"CSCR (Central Serous Chorio-retinopathy) ",
    Parapoint151:"– In CSCR accumulation of fluid beneath the retina causes blurred vision. It is diagnosed on OCT scan and is treated with macula laser.",
    point16:"Myopic Fundus",
    Parapoint161:"People with high myopia develop thin retina which can lead to lattice degeneration and risk of retinal detachment. Hence, their retina needs to be checked every year.",
    ServiceImage: [
      {
        Image01: Retina01,
      },
      {
        Image01: Retina02,
      },
      {
        Image01: Retina03,
      },
      {
        Image01: Retina04,
      },
      {
        Image01: Retina05,
      },
      {
        Image01: NRetina01,
      },
      {
        Image01: NRetina02,
      },
      {
        Image01: NRetina03,
      },
      {
        Image01: NRetina04,
      },
      {
        Image01: NRetina05,
      },
    ]
  },
  {
    id: 4,
    title: "Cornea",
    Slugs: "/cornea-specialist-in-khar-mumbai",
    metatitle:"Cornea Specialist in Khar Mumbai - Eye Life Hospital",
    metaDes:"Meet our renowned cornea specialists at Eye Life Hospital in Khar Mumbai. Your vision's well-being is our priority.",
    canonical:"https://eyelifehospital.com/cornea-specialist-in-khar-mumbai",
    head:"Cornea Specialist in Khar Mumbai",
    image: Cornea,
    Titile: "CONDITIONS IN CORNEA",
    point01: "Red Eye",
    Parapoint01: "Red eye is most commonly is due to Conjunctivitis which can be due to infection or allergy.",
    Parapoint11: "Red eye with other symptoms like pain, loss of vision etc could be due to more serious eye disease and requires immediate consultation with eye doctor.",
    point02: "Dry Eyes",
    Parapoint02: "Dry eyes may occur due to decreased tear production, abnormal tear film, increased exposure to electronic gadgets and in many other conditions. Simple dry eyes can be treated by limiting screen exposure and taking regular breaks, using artificial tears and by following the simple 20-20-20 rule",
    point03: "Ocular Trauma",
    Parapoint03: "Ocular trauma or injury to the eye is an emergency. It can happen due to any blunt or sharp object hitting the eye or due to exposure to any acid, lime or chuna or any chemical. Very often young children get injured and are often scared to open their eyes. Timely checkup and initiation of treatment is very important.",
    point04: "Eye Allergy",
    Parapoint04: "Eye allergy causes red eye with itching, watering, discomfort. Often, the cause of allergy is unknown. Applying ice cubes or cool eye packs often relieves allergy. It is also important to keep your house clean and dust free and consult doctor during exacerbations.",
    point05: "Keratoconus",
    Parapoint05: "Disease in which the Cornea or the front layer of the eye changes its shape and becomes conical leading to progressive decrease in vision and it usually affects both eyes. Common symptoms include frequent change of glasses, inability to see clearly with glasses etc. Eye check and Topography tests are important to make a diagnosis. Can be treated easily with collagen cross linking, lasers, contact lens etc if treated early.",
    point06: "Pterygium",
    Parapoint06: "Pterygium is a fleshy mass which can cover the Cornea to make it irregular and cause decrease of vision. It is usually caused by excessive exposure to sunlight. Pterygium which is increasing in size should be removed by surgery.",
    point07: "Corneal Edema",
    Parapoint07: "Corneal edema is a condition in which fluid enters into the cornea causing loss of corneal clarity and loss of vision. It is mostly seen after cataract surgery or following corneal infections and can often be treated with appropriate drops. Severe cases may be treated with advanced sutureless corneal transplantation procedure.",
    point08: "Corneal Ulcer",
    Parapoint08: "Corneal ulcer is infection of the cornea due to bacteria, fungus, virus or any other micro-organisms. If one notices a white spot on the black part of the eye along with pain, redness, loss of vision. This can be a serious problem and lead to significant loss of vision. Doctor consultation should be done at the earliest.",
    point09: "Corneal Opacity",
    Parapoint09: "Corneal opacification is partial or complete whitening of the cornea following corneal infection or injury. It causes decrease in vision and also gives poor cosmetic appearance. If it not a very dense or thick, may be removed by laser. If very dense or thick, may need corneal transplantation surgery",
    ServiceImage: [
      {
        Image01: Cornea01,
      },
      {
        Image01: Cornea02,
      },
      {
        Image01: Cornea03,
      },
      {
        Image01: Cornea04,
      },
      {
        Image01: Cornea05,
      },
      {
        Image01: Cornea06,
      },
      {
        Image01: Cornea07,
      },
      {
        Image01: Keratoconus01,
      },
    ]
  },
  {
    id: 5,
    title: "Dry Eye & Ocular Surface",
    Slugs: "/dry-eye-treatment-in-khar-mumbai",
    metatitle:"Dry Eye treatment in Khar Mumbai | Eye Life Hospital",
    metaDes:"Relieve Discomfort with Advanced Dry Eye Treatment in Khar, Mumbai. Trust Our Expert Care At Eye Life Hospital. Book Appointment Now!",
    canonical:"https://eyelifehospital.com/dry-eye-treatment-in-khar-mumbai",
    head:"Dry Eye Treatment in Khar Mumbai",
    image: DryEye,
    Titile: "Dry Eye Treatment: Comprehensive Care for Dry Eye Syndrome",
    SerivcePara01: "If you are seeking effective treatment for Dry Eye Syndrome, our Dry Eye treatment in Khar Mumbai offers comprehensive and specialized care. ",
    SerivcePara02: "Dry Eye Syndrome is a common condition characterized by insufficient lubrication and moisture on the surface of the eye. It can cause discomfort, irritation, blurred vision, and other symptoms that significantly impact daily life. Our dedicated team of eye care professionals in Khar Mumbai is committed to providing advanced and personalized treatment for Dry Eye Syndrome.",
    SerivcePara03: "At our state-of-the-art Dry Eye, we offer a range of cutting-edge diagnostic tools and treatment options to address the underlying causes of dry eyes and alleviate symptoms effectively.",
    SerivcePara04: "The first step in our Dry Eye treatment is a comprehensive evaluation of your condition. Our experienced eye specialists will conduct a detailed examination to assess the severity of your dry eyes and identify any contributing factors. This may include measuring tear production, analyzing the quality of tears, assessing the ocular surface, and evaluating any associated eyelid abnormalities.",
    SerivcePara05: "Based on the evaluation, our experts will develop a personalized treatment plan tailored to your specific needs. Our approach to Dry Eye Syndrome treatment may involve a combination of therapies to provide optimal results.",
    SerivcePara06: "One common treatment option available in our Dry Eye is the use of artificial tears and lubricating eye drops. These help to supplement the natural tears and provide temporary relief from dryness and discomfort.",
    SerivcePara07: "In addition to artificial tears, we may recommend lifestyle modifications and self-care practices that can help manage dry eyes. These may include maintaining good eyelid hygiene, practicing proper blinking exercises, using humidifiers in dry environments, and avoiding prolonged exposure to digital screens.",
    SerivcePara08: "For more severe or persistent cases of Dry Eye Syndrome, our Dry Eye offers advanced therapies such as Meibomian Gland Expression. This procedure helps to clear blocked oil glands in the eyelids, which are often a contributing factor to dry eyes. By restoring the proper function of these glands, we can improve tear production and reduce dryness.",
    SerivcePara09: "Our Dry Eye also provides access to innovative treatments like intense pulsed light (IPL) therapy. This non-invasive procedure targets inflammation in the eyelids, promoting better oil gland function and reducing dry eye symptoms.",
    SerivcePara10: "Throughout your treatment journey, our dedicated team will closely monitor your progress and make any necessary adjustments to ensure optimal outcomes. We are committed to providing personalized care and continuous support to help alleviate your dry eye symptoms effectively.",
    SerivcePara11: "In conclusion, our Dry Eye treatment in Khar Mumbai offers comprehensive care for Dry Eye Syndrome. With advanced diagnostics, personalized treatment plans, and innovative therapies, we are dedicated to providing relief and improving the quality of life for individuals with dry eyes. If you are experiencing dry eye symptoms, contact our Dry Eye in Khar Mumbai for a thorough evaluation and specialized care.",
    point01: "Ocular Surface Disease",
    Parapoint01: "Chemical injury – Accidental spillage of chemicals like acid or alkali can lead to damage to the ocular surface and limbal cells. This can lead to irreversible damage to the ocular surface leading to loss of vision and cosmetic deformity. Surgical procedure like amniotic membrane transplant, tenonplasty, in the immediate period and surgeries like ocular surface reconstruction, Boston keratoprosthesis (artificial cornea implantation), conjunctival autografting etc can help restore vision and improve cosmetic appearance.",
    Parapoint11: "Stevens Johnson Syndrome (SJS) – SJS is an auto-immune disorder leading to permanent damage to ocular surface. It most commonly occurs due to reaction against drugs like Sulphonamides, NSAID’s. Amniotic membrane grafting in the acute stage and ocular surface reconstruction procedure like Keratoprosthesis, Mucous Membrane grafting, Scleral contact lenses etc help in maintain the surface and restoring vision.",
    Parapoint02: "Ocular cicatricial pemphigoid (OCP) – OCP is an auto-immune disorder characterised by cicatrizing conjunctivitis involving the ocular surface, most commonly affective older individuals. Diagnosis can be confirmed by Conjunctival biopsy. Treatment includes initiation of medications in consultation with Physician.",
    ServiceImage: [
      {
        Image01: DryEye01,
      },
      {
        Image01: DryEye02,
      },
      {
        Image01: DryEye03,
      },
      {
        Image01: DryEye04,
      },
      {
        Image01: DryEye05,
      },
      {
        Image01: DryEye06,
      },
      {
        Image01: DryEye07,
      },
    ]
  },
  {
    id: 6,
    title: "Keratoconus",
    Slugs: "/keratoconus-treatment-in-khar-mumbai",
    metatitle:"Keratoconus Treatment in Khar Mumbai | Eye Life Hospital",
    metaDes:"Regain Clear Vision with Top-Notch Keratoconus Treatment in Khar, Mumbai. Choose Excellence Treatment At Eye Life Hospital. Book Appointment Now!",
    canonical:"https://eyelifehospital.com/keratoconus-treatment-in-khar-mumbai",
    head:"Keratoconus Treatment in Khar Mumbai",
    image: Keratoconus,
    Titile: "Keratoconus Treatment in Khar Mumbai: Restoring Vision and Quality of Life",
    SerivcePara01: "If you're seeking effective treatment for keratoconus, our specialized keratoconus treatment in Khar Mumbai offers comprehensive care and innovative solutions.",
    SerivcePara02: "Keratoconus is a progressive eye disorder that causes thinning and bulging of the cornea, resulting in distorted vision. It can significantly impact daily life, making it challenging to perform routine tasks and affecting overall quality of life. At our advanced eye care center in Khar Mumbai, we provide specialized treatment options to address keratoconus and restore vision.",
    SerivcePara03: "Our dedicated team of eye care professionals in Khar Mumbai is well-versed in the latest advancements and techniques for keratoconus treatment. We offer personalized treatment plans to meet the unique needs of each patient.",
    SerivcePara04: "The first step in our keratoconus treatment is a thorough evaluation of your condition. Our experienced eye specialists will conduct a series of tests and assessments to accurately diagnose keratoconus and determine the severity of the condition. These may include corneal mapping, topography, and corneal thickness measurements.",
    SerivcePara05: "Based on the evaluation, our experts will develop a customized treatment plan tailored to your specific requirements. Our approach to keratoconus treatment may involve a combination of non-invasive and surgical interventions to achieve the best possible outcomes.",
    SerivcePara06: "One common non-invasive treatment option for keratoconus is the use of specialized contact lenses, such as scleral lenses or rigid gas permeable (RGP) lenses. These lenses help to correct the irregular shape of the cornea, improving visual clarity and reducing distortions.",
    SerivcePara07: "In cases where the progression of keratoconus is more advanced, surgical interventions may be necessary. Our skilled surgeons are proficient in performing advanced procedures such as corneal collagen cross-linking (CXL), which strengthens the cornea and slows down the progression of the condition. Another surgical option is implanting intraocular contact lenses (ICLs) to correct vision in individuals with keratoconus.",
    SerivcePara08: "Throughout your keratoconus treatment journey, our dedicated team will provide continuous support and monitor your progress closely. We will ensure that you receive comprehensive care and that all your concerns and questions are addressed.",
    SerivcePara09: "At our Mumbai-based keratoconus treatment center, we are committed to helping individuals with keratoconus regain their vision and improve their quality of life. Our state-of-the-art facilities, combined with our expertise and personalized approach, make us a preferred choice for keratoconus treatment in Khar Mumbai.",
    SerivcePara10: "If you or a loved one is dealing with keratoconus, don't let it hinder your visual potential and quality of life. Contact our specialized keratoconus treatment center in Khar Mumbai to schedule a consultation and explore the innovative treatment options available to you.",
    ServiceImage: [
      {
        Image01: Keratoconus02,
      },
      {
        Image01: NKeratoconus01,
      },
    ]
  },
  {
    id: 7,
    title: "Glaucoma",
    Slugs: "/glaucoma-specialist-in-khar-mumbai",
    metatitle:"Glaucoma Specialist In Khar Mumbai | Eye Life Hospital",
    metaDes:"Trust Your Eyes to the Leading Glaucoma Specialist in Khar, Mumbai. Preserve Your Vision At Eye Life Hospital. Book Appointment Now!",
    canonical:"https://eyelifehospital.com/glaucoma-specialist-in-khar-mumbai",
    head:"Glaucoma Specialist In Khar Mumbai",
    image: Glaucoma,
    SerivcePara01: "Our eyes function like a camera. Just like a camera captures the image but to see its photo we need to develop the film. Similarly in the human body, the eye captures the image and then the image from the eye is sent to the brain via the optic nerve. When the message reaches the brain, we see the image. This is similar to a modern digital camera which is connected through an external cable to the TV and we can see the photo on the TV. In some people, when the eye pressure (not the blood pressure) goes up, it presses on the optic nerve. Due to pressure on the optic nerve, though the image is captured properly by the eye, the image does not get transferred to the brain. Initially only part of the optic nerve gets damaged due to which the person starts losing side vision (Peripheral vision). This is called as Glaucoma. Since the central vision is not lost initially, the person cannot feel that there is any problem. Hence Glaucoma is often called as ‘the silent killer of sight’. If the pressure remains high for long time, the whole optic nerve can get damaged which can lead to complete vision loss. ",
    SerivcePara02: "Glaucoma is managed by a team of Optometrists and Eye Doctors. Dr. Gaurav Shah and Dr. Sushmita Shah manage cases of Glaucoma. They have taken their training from Sankara Nethralaya, Chennai, L V Prasad Eye Institute, Hyderabad  and from premier institutes in USA. ",
    PointHead001: "Various Instruments and Treatment Modalities Used In the Diagnosis And Management Glaucoma Include:",
    PointHeadPoint01: "1.	Pachymetry & Applanation Tonometry – Combination of 2 instruments is used to most accurately measure eye pressure.",
    PointHeadPoint02: "2.	Gonioscopy – This test allows one to determine the type of Glaucoma – open angle and angle closure, based on which the treatment plan can be decided.",
    PointHeadPoint03: "3.	OCT (Optical Coherence Tomography) – It is a laser-based scanning machine which can scan the retina, cornea and optic nerve to evaluate retinal diseases, corneal diseases and glaucoma. Eye Life uses a high definition imported OCT machine to give safe, non-invasive analysis of the retinal disease and glaucoma within minutes. ",
    PointHeadPoint04: "4.	Perimetry – This is a sophisticated instrument which allows measurement of side / peripheral vision.",
    PointHeadPoint05: "5.	Eye Drops for Glaucoma – Eye pressure can be reduced by eye drops. However, once started, eye drops for Glaucoma have to be used lifelong.",
    PointHeadPoint06: "6. Laser Treatment for Glaucoma – it is a form of light treatment given to allow better drainage of eye fluid and thus lower the risk of eye pressure from increasing. ",
    PointHeadPoint07: "7. Glaucoma Surgery – Trabeculectomy surgery is the most successful surgery to lower eye pressure. Stents can also be used to lower eye pressure. Rarely, if eye pressure does not come under control with stent or trabeculectomy, valve surgery can be done.",
    Parapoint01: "If you are looking for glaucoma treatment or glaucoma treatment in Khar Road, it is advisable to research and choose a reputable clinic or eye center. Consultation with experienced glaucoma specialists can provide personalized treatment options and ensure the best possible outcomes.",
    Parapoint11: "Please note that this information is provided for general knowledge and should not replace professional medical advice. Consultation with an ophthalmologist or glaucoma specialist is necessary for individualized guidance regarding glaucoma treatment.",
 
    ServiceImage: [
      {
        Image01: Glaucoma,
      },
      {
        Image01: NRetina03,
      },
      {
        Image01: NGlaucoma01,
      },
      {
        Image01: NGlaucoma02,
      },
    ]
  },
  {
    id: 8,
    title: "Uveitis",
    Slugs: "/eye-doctor-for-uveitis-in-khar-mumbai",
    metatitle:"Eye Doctor For Uveitis In Khar Mumbai | Eye Life Hospital",
    metaDes:"Get Relief from Uveitis with Our Specialized Doctor in Khar, Mumbai. Your Eye Health Matters. At Eye Life Hospital Book Appointment Now!",
    canonical:"https://eyelifehospital.com/eye-doctor-for-uveitis-in-khar-mumbai",
    head:"Eye Doctor For Uveitis In Khar Mumbai",
    image: uveitis,
    Titile: "",
    SerivcePara01: "UVEITIS is eye inflammation which affects the layer of the eye that has the maximum blood supply. The eye may turn red, painful and vison may get blurred. Uveitis may be recurrent in some individuals.",
    SerivcePara02: "Timely consultation with an ophthalmologist is crucial if signs of uveitis are observed, as untreated uveitis can lead to permanent vision loss. Uveitis can be associated with underlying diseases such as tuberculosis, sarcoidosis, and collagen vascular diseases. Comprehensive eye tests and blood tests are important for accurate diagnosis and effective uveitis treatment.",
    PointHead001: "Here Are Key Facts About Uveitis:",
    PointHeadPoint01: "1.	Uveitis can cause redness and pain in the eye.",
    PointHeadPoint02: "2.	It can affect one eye or both eyes.",
    PointHeadPoint03: "3.	Recurrent and chronic uveitis cases are common.",
    PointHeadPoint04: "4.	Untreated uveitis can result in vision loss.",
    PointHeadPoint05: "5.	Uveitis may lead to complications like cataract formation.",
    PointHeadPoint06: "6.	Tuberculosis can trigger uveitis in some individuals.",
    PointHeadPoint07: "7.	Collagen vascular diseases increase the risk of uveitis.",
    PointHeadPoint08: "8.	Treatment may involve collaboration with a physician.",
    PointHeadPoint09: "9.	Uveitis is typically managed with eye drops, injections, and oral medications.",
    PointHeadPoint10: "10. While uveitis can affect people of all ages, it is rare in young individuals.",
    Parapoint01: "If you are searching for uveitis treatment in Khar Mumbai or elsewhere, it is advisable to seek specialized care from reputable clinics or eye centers with expertise in uveitis management. Prompt medical attention and adherence to the prescribed treatment plan are essential for favourable outcomes. Dr. Gaurav Shah at Eye Life is an expert in management of uveitis and has vast experience in managing both infective and non-infective uveitis. ",
    Parapoint11: "Please note that this information is provided for educational purposes and should not replace professional medical advice. Consultation with a specialist in uveitis treatment is necessary for personalised guidance and care. ",
    ServiceImage: [
      {
        Image01: uveitis,
      },
    ]
  },
  {
    id: 9,
    title: "Squint",
    Slugs: "/squint-surgery-in-khar-mumbai",
    metatitle:"Squint Surgery in Khar Mumbai | Eye Life Hospital",
    metaDes:"Transform Your Gaze with Leading Squint Surgery in Khar, Mumbai. Discover Confident Vision! Book Appointment Now!",
    canonical:"https://eyelifehospital.com/squint-surgery-in-khar-mumbai",
    head:"Squint Surgery in Khar Mumbai",
    image: Squint,
    Titile: "SQUINT or deviation of eyes is a condition in which the two eyes do not move in the same direction. It is commonly seen in children. It may occur in adults also.",
    SerivcePara01: "Squint, also known as strabismus, refers to a condition in which the two eyes do not align or move in the same direction. It is commonly observed in children but can also occur in adults.",
    SerivcePara02: "Diagnosing squint in children is important as it may be associated with decreased vision in one eye. Treatment for squint in children often involves the use of glasses, and in rare cases, surgery may be required.",
    SerivcePara03: "In adults, squint can develop as a result of an injury or accident. Individuals with diabetes may also experience the sudden onset of squint due to nerve problems.",
    PointHead001: "Here are a few important facts about squint:",
    PointHeadPoint01: "1.	Squint can be present from birth or may develop later in life.",
    PointHeadPoint02: "2.	Poor vision can contribute to the occurrence of squint.",
    PointHeadPoint03: "3.	In some cases, squint in children can be managed with glasses.",
    PointHeadPoint04: "4.	Surgery may be necessary in rare cases of squint in children.",
    PointHeadPoint05: "5.	Squint can be a consequence of eye and head injuries.",
    PointHeadPoint06: "6.	People with diabetes may experience sudden squint due to nerve-related issues.",
    PointHeadPoint07: "7.	Squint can be caused by problems with the eye muscles responsible for eye movement.",
    PointHeadPoint08: "8.	If left untreated in children, squint can lead to a condition called lazy eye (amblyopia), resulting in permanent vision loss.",
    PointHeadPoint09: "9.	Squint treatment in adults typically involves surgical intervention.",
    Parapoint01: "If you are seeking squint treatment in Khar Road or elsewhere, it is recommended to find a reputable clinic or eye center that specializes in squint management. Consulting with experienced doctors or ophthalmologists can provide you with personalized treatment options.",
    Parapoint11: "Please note that this information is provided for general knowledge and should not replace professional medical advice. Consultation with an ophthalmologist or a specialist in squint treatment is necessary to receive individualized guidance regarding squint management.",
    ServiceImage: [
      {
        Image01: Squint,
      },
      {
        Image01: NSquint,
      },
    ]
  },
  {
    id: 10,
    title: "Paediatric Ophthalmology",
    Slugs: "/eye-doctor-for-paediatric-ophthalmologist-mumbai",
    metatitle:"Eye Doctor For Paediatric Ophthalmologist Mumbai",
    metaDes:"Trust Your Child's Vision to Our Specialist Ophthalmologist in Mumbai. Comprehensive Pediatric Eye Care!",
    canonical:"https://eyelifehospital.com/eye-doctor-for-paediatric-ophthalmologist-mumbai",
    head:"Eye Doctor For Paediatric Ophthalmologist Mumbai",
    image: PedOphthalmolgy,
    Titile: "Pediatric ophthalmology specializes in diagnosing and treating eye diseases in children. Here are common eye conditions that can affect children:",
    point01: "Refractive Error/Glass Number: ",
    Parapoint01: "Children often experience refractive errors like myopia (nearsightedness), hypermetropia (farsightedness), and astigmatism. If left untreated, these conditions can lead to lazy eye or amblyopia. Excessive close-up activities, such as prolonged TV watching or gadget use, can contribute to myopia progression. Encouraging outdoor play is recommended to reduce the risk. Research is ongoing on the use of eye drops for myopia treatment.",
    point02: "Lazy Eye/Amblyopia:",
    Parapoint02: "Lazy eye or amblyopia refers to poor vision in one or both eyes. It can result from untreated refractive errors, cataracts, squint, or other eye conditions. Regular use of glasses helps treat lazy eye caused by refractive errors. Some children may require eye patching and exercises. Early treatment is crucial to prevent permanent vision loss.",
    point03: "Cataract in Children: ",
    Parapoint03: "While rare, cataracts can occur in children at birth or shortly after. They can affect one or both eyes. Laser cataract surgery is the usual treatment, followed by wearing glasses and regular follow-up with an eye doctor.",
    point04: "Corneal Opacity in Children: ",
    Parapoint04: "Corneal opacity presents as a white spot on the cornea and may be present from birth or develop later. Causes include corneal infections, nutritional deficiencies, injuries, or maternal infections. Surgical interventions like corneal transplantation or optical iridectomy may be necessary if corneal opacity leads to lazy eye. Nutritional deficiencies, such as vitamin A deficiency, should be addressed promptly.",
    point05: "Watering of Eyes in Children: ",
    Parapoint05: "Watering or discharge from a child's eyes is often due to tear outflow blockage. Gentle massage can help treat this condition in very young children. Early consultation with an eye doctor is important for proper management.",
    point06: "ROP (Retinopathy of Prematurity):",
    Parapoint06: "Premature children (Infants born before 8-9 months) or low birth weight children develop retina problem in both eyes and can lead to permanent loss of vision. Hence, such infants should be examined by a retina specialist or paediatric ophthalmologist at 4 to 6 weeks after birth. If ROP is diagnosed, the eye doctor will usually examine the infant every few days. If the disease is progressing and there is a risk for blindness, the eye doctor will advise retina laser, intravitreal injections or rarely retina surgery.",
    Parapoint01: "If you are seeking pediatric ophthalmology treatment in Khar Road or elsewhere, it is advisable to find reputable clinics or eye centers specializing in pediatric eye conditions. Including the keywords 'pediatric ophthalmology treatment' in your search can be helpful.",
    Parapoint11: "Please note that this information is provided for educational purposes and should not replace professional medical advice. Consultation with a pediatric ophthalmologist is necessary for personalized guidance and treatment. Pediatric ophthalmology Treatment In Khar Road Pediatric ophthalmology Treatment Pediatric ophthalmology Treatment Pediatric ophthalmology Treatment",
    ServiceImage: [
      {
        Image01: PedOphthalmolgy,
      },
      {
        Image01: NPaediatricOphthalmology,
      },
    ]
  },
  {
    id: 11,
    title: "Oculoplasty",
    Slugs: "/oculoplasty-treatment-in-khar-Mumbai",
    metatitle:"Oculoplasty Treatment In Khar Mumbai | Eye Life Hospital",
    metaDes:"Enhance Your Eye's Beauty with Oculoplasty Treatment in Khar, Mumbai. Book Your Appointment Now!",
    canonical:"https://eyelifehospital.com/oculoplasty-treatment-in-khar-Mumbai",
    head:"Oculoplasty Treatment In Khar Mumbai",
    image: Oculoplasty,
    Titile: "",
    SerivcePara01: "Oculoplasty is a specialized field of ophthalmology that focuses on the treatment of eyelid and periocular conditions. Some common oculoplasty diseases include:",
    point01: "Ptosis:",
    Parapoint01: "Ptosis refers to the drooping of the upper eyelid, where it appears lower than the other eye. It can be present since birth (congenital ptosis) or develop later in life due to injury or aging. Ptosis can cause functional and cosmetic concerns, and surgical treatment is often required to lift the eyelid to a normal position.",
    point02: "Entropion/Ectropion: ",
    Parapoint02: "Entropion is the inward turning of the eyelid, while ectropion is the outward turning of the eyelid. These conditions can cause irritation, redness, and tearing. Surgical correction is typically performed to restore the normal position of the eyelid and improve eye comfort.",
    point03: "Proptosis: ",
    Parapoint03: "Proptosis refers to the protrusion or bulging of one or both eyeballs. It is commonly associated with thyroid eye disease, orbital tumors, or other underlying conditions. Treatment approaches may involve medical management, surgery, or a combination of both, depending on the underlying cause and severity.",
    point04: "Watering of Eyes: ",
    Parapoint04: "Excessive tearing or watering of the eyes can occur due to tear outflow blockage or other underlying factors. Diagnosis involves a thorough evaluation by an oculoplasty specialist, which may include tests to assess tear drainage. Treatment options range from conservative measures to surgical interventions, depending on the cause and severity of the condition.",
    point05: "Cosmetic Problems: ",
    Parapoint05: "Oculoplasty also addresses various cosmetic concerns related to the eyes, such as eyelid warts, puffiness, abnormal pigmentation, xanthelasma (yellowish plaques on the eyelids), and sunken eyes. Treatment options vary depending on the specific cosmetic issue and may include surgical or non-surgical approaches.",
    Parapoint01: "If you are searching for oculoplasty treatment in Khar Road or elsewhere, it is recommended to seek care from reputable clinics or eye centers specializing in oculoplasty procedures. Including the keywords 'oculoplasty treatment' in your search can help you find relevant information and providers.",
    Parapoint11: "Please note that the information provided here is for educational purposes and should not replace professional medical advice. It is essential to consult with an oculoplasty specialist for personalized guidance and treatment. Oculoplasty Treatment In Khar Road Oculoplasty Treatment Oculoplasty Treatment Oculoplasty Treatment",
    ServiceImage: [
      {
        Image01: Oculoplasty,
      },
      {
        Image01: NOculoplasty,
      },
      {
        Image01: NOculoplasty01,
      },
    ]
  },
  {
    id: 12,
    title: "Neuro Ophthalmology",
    Slugs: "/neuro-ophthalmology-treatment-in-khar-mumbai",
    metatitle:"Neuro Ophthalmology Treatment In Khar Mumbai",
    metaDes:"Trust Your Neuro-Ophthalmology Care to Experts in Khar, Mumbai. Enhance Your Eye Health At Eye Life Hospital. Book Your Appointment Now!",
    canonical:"https://eyelifehospital.com/neuro-ophthalmology-treatment-in-khar-mumbai",
    head:"Neuro Ophthalmology Treatment In Khar Mumbai",
    image: neutoOpthelmo,
    Titile: "Comprehensive Neuro-Ophthalmology Treatment in Khar Mumbai",
    SerivcePara01: "If you're seeking specialized care for neuro-ophthalmology conditions, our clinic in Khar Mumbai offers comprehensive treatment options delivered by experienced specialists.",
    SerivcePara02: "Neuro-ophthalmology is a specialized field that deals with the diagnosis and management of visual problems related to the nervous system. At our Mumbai-based clinic, we have a dedicated team of neuro-ophthalmology specialists who are well-versed in addressing a wide range of neuro-ophthalmic conditions",
    SerivcePara03: "When you visit our clinic for neuro-ophthalmology treatment, our skilled and compassionate team will conduct a thorough evaluation to understand the underlying cause of your visual symptoms. We recognize that each patient is unique, and we take a personalized approach to develop an effective treatment plan tailored to your specific needs.",
    SerivcePara04: "Our neuro-ophthalmology specialists in Khar Mumbai are experienced in diagnosing and managing various conditions, including optic nerve disorders, visual field defects, double vision (diplopia), eyelid abnormalities affecting vision, disorders affecting eye movements, and other neurologic conditions that impact vision.",
    SerivcePara05: "Using advanced diagnostic techniques and state-of-the-art equipment, we ensure accurate diagnosis and provide evidence-based treatments for neuro-ophthalmic conditions. Treatment options may include medical management, specialized medications, vision rehabilitation, and coordination of care with other specialists involved in your overall healthcare.",
    SerivcePara06: "At our Mumbai clinic, we strive to create a patient-centered environment where open communication and shared decision-making are prioritized. We understand the impact that neuro-ophthalmic conditions can have on your daily life, and we are committed to providing comprehensive support and guidance throughout your treatment journey.",
    SerivcePara07: "Our clinic is equipped with modern facilities to deliver advanced neuro-ophthalmology treatment. We stay updated with the latest advancements in the field to offer the most effective and cutting-edge solutions for our patients. ",
    SerivcePara08: "Follow-up visits are an integral part of neuro-ophthalmology treatment to monitor your progress, adjust treatment plans if needed, and address any concerns or questions you may have. Our dedicated team is committed to ensuring your comfort, satisfaction, and optimal visual outcomes.",
    SerivcePara09: "Choosing our Mumbai-based clinic for neuro-ophthalmology treatment means entrusting your vision and well-being to a team of experienced specialists who are dedicated to delivering exceptional care. We strive to improve your quality of life by effectively managing your neuro-ophthalmic condition and optimizing your visual function.",
    SerivcePara10: "If you require neuro-ophthalmology treatment or have concerns related to your vision and the nervous system, please don't hesitate to contact our clinic in Khar Mumbai. Our friendly staff will assist you in scheduling a consultation and guide you through the process of receiving specialized care from our experienced neuro-ophthalmology team.",
    ServiceImage: [
      {
        Image01: neutoOpthelmo,
      },
    ]
  },

  {
    id: 13,
    title: "Contact Lens",
    Slugs: "/eye-doctor-for-contact-lens-in-mumbai-eye-life-hospital",
    metatitle:"Eye Doctor For Contact Lens In Mumbai | Eye Life Hospital",
    metaDes:"Experience Comfort and Clarity with Contact Lenses in Mumbai. Consult Our Specialist Today. Book Your Appointment Now!",
    canonical:"https://eyelifehospital.com/eye-doctor-for-contact-lens-in-mumbai-eye-life-hospital",
    head:"Eye Doctor For Contact Lens In Mumbai",
    image: lens,
    Titile: "Comprehensive Contact Lens Treatment in Khar Mumbai",
    SerivcePara01: "If you're looking for professional and reliable contact lens treatment, our clinic in Khar Mumbai offers comprehensive solutions to meet your vision needs.",
    SerivcePara02: "Contact lenses are a popular and convenient alternative to eyeglasses for correcting vision problems. Whether you have nearsightedness, farsightedness, astigmatism, or presbyopia, our experienced team in Khar Mumbai can help you find the right contact lens options for your specific needs.",
    SerivcePara03: "When you visit our clinic for contact lens treatment, our skilled optometrists will conduct a thorough examination of your eyes to determine the most suitable contact lens prescription. We take into consideration your visual requirements, eye health, lifestyle, and personal preferences to provide customized contact lens solutions.",
    SerivcePara04: "Our clinic offers a wide range of contact lens options, including daily disposable lenses, monthly lenses, toric lenses for astigmatism, multifocal lenses for presbyopia, and specialized lenses for dry eyes or other specific eye conditions. Our experienced staff will guide you through the selection process, ensuring that you receive contact lenses that provide optimal comfort, vision, and eye health.",
    SerivcePara05: "Proper contact lens fitting is essential to ensure a comfortable and successful experience. Our optometrists have expertise in fitting various types of contact lenses, including soft lenses, rigid gas permeable lenses, and specialty lenses. We utilize advanced diagnostic tools and measurements to ensure an accurate fit and prescription for your eyes.",
    SerivcePara06: "During the contact lens fitting process, we will provide detailed instructions on how to properly handle, clean, and care for your contact lenses. We prioritize patient education and provide ongoing support to help you maintain healthy eyes and maximize the lifespan of your contact lenses.",
    SerivcePara07: "Regular follow-up visits are an integral part of contact lens treatment to monitor your eye health, assess the fit and comfort of your lenses, and make any necessary adjustments to your prescription. Our dedicated team is committed to your satisfaction and will address any concerns or questions you may have throughout your contact lens journey.",
    SerivcePara08: "At our Mumbai clinic, we not only provide contact lens treatment but also offer guidance on lens insertion and removal techniques, lens hygiene practices, and tips for managing common contact lens-related issues.",
    SerivcePara09: "Choosing our clinic for contact lens treatment means receiving high-quality care from a team of experienced optometrists who are dedicated to providing excellent vision correction solutions. We stay updated with the latest advancements in contact lens technology and ensure that you receive the most suitable and comfortable lenses for your unique needs.",
    SerivcePara10: "If you're interested in contact lens treatment or have any questions about contact lenses, please don't hesitate to contact our clinic in Khar Mumbai. Our friendly staff will assist you in scheduling a consultation and guide you through the process of finding the right contact lens options for clear and comfortable vision.",
    ServiceImage: [
      {
        Image01: lens,
      },
      {
        Image01: ContactLens01,
      },
      {
        Image01: Ncatract01,
      }
    ]
  },
  {
    id: 14,
    title: "Eye Trauma",
    Slugs: "/eye-injury-treatment-in-khar-mumbai",
    metatitle:"Eye Trauma - Eye Injury Treatment In Khar Mumbai",
    metaDes:"Rapid Relief and Recovery with Eye Injury Treatment in Khar, Mumbai. Your Vision Matters. Book Your Appointment Today!",
    canonical:"https://eyelifehospital.com/eye-injury-treatment-in-khar-mumbai",
    head:"Eye Trauma - Eye Injury Treatment In Khar Mumbai",
    image: Trauma,
    Titile: "Eye Trauma Treatment in Khar Mumbai: Comprehensive Guide",
    SerivcePara01: "Eye trauma or injury to the eye is an emergency that requires immediate attention. In Khar Mumbai, there are various treatment options available to address different types of eye trauma. This comprehensive guide provides information on eye trauma treatment.",
    SerivcePara02: "The most common type of eye injury involves small foreign bodies entering the ocular surface or cornea. Fortunately, these injuries can be easily treated by a doctor in the outpatient department (OPD) by removing the foreign bodies promptly.",
    SerivcePara03: "However, more serious injuries occur when the cornea or sclera tears due to sharp objects, resulting in open globe injuries. Repairing such injuries requires surgery in an operation theatre, and it is crucial not to delay the surgical intervention. In some cases, foreign bodies may penetrate inside the eyeball following a corneal or scleral tear, necessitating their removal through multiple surgical procedures.",
    SerivcePara04: "Accidental chemical spills into the eye, often involving substances like lime or 'Chuna,' are unfortunately common in our country. Children are particularly vulnerable to these incidents, which can lead to severe long-term damage to the ocular surface. Managing chemical injuries involves immediate eye washing, treatment with specialized eye drops, and, in severe cases, surgical procedures such as amniotic membrane grafting and tenonplasty. Long-term complications of chemical injuries may include limbal stem cell deficiency (LSCD), which can be treated with procedures like limbal stem cell transplantation and the use of Keratoprosthesis or Artificial cornea.",
    SerivcePara05: "When seeking eye trauma treatment in Khar Mumbai, it is crucial to consult a qualified eye specialist or visit a reputable eye hospital. Timely and appropriate medical intervention is essential to prevent further damage, alleviate pain, and ensure a successful recovery.",
    SerivcePara06: "Remember, eye trauma should never be taken lightly. Eye injuries are emergencies that require immediate medical attention. If you or someone you know experiences an eye injury, seek professional help without delay. By doing so, you can ensure prompt treatment and minimize the risk of long-term complications.",
    ServiceImage: [
      {
        Image01: Trauma01,
      },
      {
        Image01: Trauma02,
      },
      {
        Image01: Trauma03,
      },
      {
        Image01: Trauma04,
      },
      {
        Image01: Trauma05,
      },
    ]
  },
  {
    id: 15,
    title: "Low Vision Aids",
    Slugs: "/low-vision-specialty-clinic-in-khar-mumbai",
    metatitle:"Low Vision Specialty Clinic in Khar Mumbai | Eye Life Hospital",
    metaDes:"Regain Independence with the Top Low Vision Specialty Clinic in Khar Mumbai. Discover Hope At Eye Life Hospital. Book Appointment Now!",
    canonical:"https://eyelifehospital.com/low-vision-specialty-clinic-in-khar-mumbai",
    head:"Low Vision Specialty Clinic in Khar Mumbai",
    image: LowVisionAids,
    SerivcePara01: "Low vision aids are devices that are designed to improve vision in people with low visual potential. Patients across all age groups can use these devices.",
    SerivcePara02: "These devices include magnifiers like stand and hand magnifiers, tablet computers, phones etc.",
    SerivcePara03: "Patients with degenerative retinal conditions usually benefit from these devices.",
    SerivcePara04: "LVA also helps children with eye or retinal abnormalities since birth to pursue their studies.",
    SerivcePara05: "Before prescribing a LVA, trial has to be done and then the LVA is dispensed.",
    SerivcePara06: "If you have poor vision, ask your doctor whether you are eligible for a LVA trial.",
    ServiceImage: [
      {
        Image01: LowVisionAids,
      },
      {
        Image01: LowVisionAids01,
      },
      {
        Image01: LowVisionAids02,
      },
    ]
  },
];

export default ServcieData;
