import React, { useState, useLayoutEffect } from 'react';
import './services.css';
import servcieData from '../ServiceData/ServiceData.jsx';
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';

const Services = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const [serviceID, setServiceId] = useState('')

    const viewDetail = (item, index) => {
        setServiceId(item.id);
    }
    return (
        <div>
            <MetaTags>
                <title>Eye Treatment Services In Khar | Eye Life Hospital</title>
                <meta title="Eye Treatment Services In Khar | Eye Life Hospital" />
                <meta
                    name="description"
                    content="Experience transformative ocular care in Mumbai's top eye clinics. Rediscover crystal-clear vision with cutting-edge eye treatment services in Khar."
                />
                <meta
                    name="keywords"
                    content="Best Eye Hospital In Khar Mumbai,Cataract surgery In Khar Mumbai,LASIK Surgery in Khar Mumbai,Retina Surgery in Khar Mumbai,Cornea Specialist in Khar Mumbai,Best Eye Doctor in Khar Mumbai,Laser Eye Surgery In Khar Mumbai "
                />
                <link rel="canonical" href="https://eyelifehospital.com/eye-treatment-services-in-khar" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Eye Treatment Services In Khar | Eye Life Hospital" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://eyelifehospital.com/" />
                <meta property="og:description" content="Experience transformative ocular care in Mumbai's top eye clinics. Rediscover crystal-clear vision with cutting-edge eye treatment services in Khar." />
                <meta property="og:image" content="https://eyelifehospital.com/static/media/logo-footer.0798714161edb8b2d5b9.png" />
            </MetaTags>
            {/* services Home */}
            <div className='serviceHome'>
                <div className='servicesHomeInner'>
                    <h1>Eye Treatment Services In Khar</h1>
                </div>
            </div>

            {/* services List */}
            <div className='serviceSection'>
                <div className='serviceSectionInner'>
                    <div className='ServiceSectionsCards'>
                        <div className='row'>
                            {
                                servcieData.map((item, index) => {
                                    const { id, title, image, shortDesc, Slugs } = item
                                    return (
                                        <>
                                            <div className='col-md-4 p-3 d-flex justify-content-center align-items-center'>

                                                <div className="serviceCard">
                                                    <div className="serviceCardImg">
                                                        <img src={image} alt="" />
                                                    </div>
                                                    <div className="serviceCardText">
                                                        <h2>{title}</h2>
                                                        <h6> {shortDesc}</h6>

                                                        <p onClick={() => viewDetail(item, index)}>
                                                            <Link to={`${Slugs}`}>Know More </Link></p>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Services;