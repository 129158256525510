import './App.css';
import NavBar from './components/Navbar/navbar.js';
import Home from './components/Home/Home.js';
import About from './components/About/About';
// import Doctors from './components/Doctors/doctors.js';
import Contact from './components/Contact/contact.js';
import Footer from './components/Footer/footer.js';
import Gallery from './components/Gallery/gallery.js';
import Services from './components/Services/Services.js';
import ServiceDetail from './components/serviceDeatil/ServiceDetail.js';
import Conditions from './components/PatientInfo/Conditions';
import Procedure from './components/Procedure/procedure.jsx';
import International from './components/International/international.jsx';
import Testimonal from './components/Testimonal/Testiomnal.jsx'
import DownEnquery from './components/DownEnquery/downEnquiry.js';
import DoctorDetail from './components/DoctorDetail/doctorDetail.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CallIcon from './components/CallIcon/CallIcon.js';
import GoToTop from './components/GoToTop/GoToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import DrSushmita from './components/DrSushmita/DrSushmita';
import ServcieData from './components/ServiceData/ServiceData';
import Publications from './components/Publications/Publications';
import DrGauravShah from './components/DrGauravShah/DrGauravShah';
import ErrorPage from "./components/Error Page/ErrorPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/laser-eye-surgery-in-khar-mumbai' element={<About />} />
          <Route path='/best-eye-doctor-in-khar-mumbai' element={<DoctorDetail />} />
          <Route path='/doctor-sushmita-shah' element={<DrSushmita />} />
          <Route path='/doctor-Gaurav-Shah' element={<DrGauravShah />} />
          <Route path='/conditions' element={<Conditions />} />
          <Route path='/eye-treatment-services-in-khar' element={<Services />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/Publications' element={<Publications />} />
          <Route path='/procedure' element={<Procedure />} />
          <Route path='/eye-treatment-in-khar-mumbai' element={<International />} />
          <Route path='/testimonals' element={<Testimonal />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/dr-sushmita' element={<DrSushmita />} />
          <Route path='/dr-gaurav-shah' element={<DoctorDetail />} />
          <Route path='/*' element={<ErrorPage />} /> 

          {
            ServcieData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.Slugs}
                    element={<ServiceDetail />} />
                </>
              )
            })
          }
        </Routes>
        <DownEnquery />
        <CallIcon />
        <GoToTop />
        <Footer />
      </BrowserRouter >
    </div>
  );
}

export default App;
