import React from 'react';
import "../Error Page/ErrorPage.css";
import { Link } from 'react-router-dom';
import ErrorIcon from "../../images/404 vectro image.avif";

const ErrorPage = () => {
    return (
        <>
            <section className="Error_page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="error_title">
                                <div className="errorlogo">
                                    <img src="http://localhost:3000/static/media/logo-footer.0798714161edb8b2d5b9.png"
                                        alt="company logo" />
                                </div>
                                <div className="ErrorDesc">
                                    <h2><span>404</span> That's An Error</h2>
                                    <p>The requested URL /badpage was not found on this server. That's all we know. Please Go To Home Page</p>
                                    <Link to="/">Home</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ErrorRight">
                                <div className="errorImg">
                                    <img src={ErrorIcon} alt="404 Error Digital Marketing" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ErrorPage;
