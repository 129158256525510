import React from 'react';
import './footer.css';
import logo from "../../images/logo-footer.png";
import { Link } from 'react-router-dom';


const footer = () => {
    return (
        <div>
            <footer class="footerSection ">
                <div class="container ">
                    <div class="row ">
                        <div class="col-md-3 col-12  ">
                            <div class="comapnyDetail ">
                                <img src={logo} alt="footer logo" class="logo " />
                                <p>Eye Health Personalised</p>
                            </div>
                        </div>
                        <div class="col-md-9 col-12">
                            <div class="quickLink ">
                                {/* <h2>Quick Link</h2> */}
                                <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/cataract-surgery-in-khar-mumbai">Cataract</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/lasik-surgery-in-khar-mumbai">Lasik</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/retina-surgery-in-khar-mumbai">Retina</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/cornea-specialist-in-khar-mumbai">Cornea</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/dry-eye-treatment-in-khar-mumbai">Dry Eyes</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/keratoconus-treatment-in-khar-mumbai">Keratoconus</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/glaucoma-specialist-in-khar-mumbai">Glaucoma</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/eye-doctor-for-uveitis-in-khar-mumbai">Uveitis</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/squint-surgery-in-khar-mumbai">Squint</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/neuro-ophthalmology-treatment-in-khar-mumbai">Neuro Ophthalmology</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/eye-injury-treatment-in-khar-mumbai">Eye Trauma</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/eye-doctor-for-paediatric-ophthalmologist-mumbai">Paediatric Ophthalmology</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/oculoplasty-treatment-in-khar-Mumbai">Oculoplasty</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/eye-doctor-for-contact-lens-in-mumbai-eye-life-hospital">Contact Lens</Link>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='Footer-Item'>
                                            <Link to="/low-vision-specialty-clinic-in-khar-mumbai">Low Vision Aids</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default footer