import React, { useState, useLayoutEffect } from 'react';
import './procedure.css';
import ProcedureCard from '../ProcedureCard/procedureCard.jsx';

// images
import ThreeDMaestroOCT from '../../images/procedure/3D-Maestro-OCT.JPG';
import Autorefractometer from '../../images/procedure/Autorefractometer-and-Non-Contact-Tonometer.JPG';
import BoylesApparatus from '../../images/procedure/Boyles-Apparatus-with-Pulse-oximeter.JPG';
import HighSpeedVitrectomy from '../../images/procedure/High-Speed-Vitrectomy-Machine.JPG';
import Lumera from '../../images/procedure/Lumera-1-Surgical-Operating-Microscope.JPG';
import OAOptical from '../../images/procedure/OA-2000-Optical-Biometer.JPG';
import SiriusScheimpflug from '../../images/procedure/Sirius-Scheimpflug-Topographer.JPG';
import SovereignCompact from '../../images/procedure/Sovereign-Compact-Machine.JPG';

const Procedure = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div>
            {/* procedure banner */}
            <div className='procedureHome'>
                <h1>Procedures</h1>
            </div>

            <div className='container'>
                {/* prodecure detail */}
                <div className='procedureList'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={ThreeDMaestroOCT} cardTitle="3D Maestro OCT" className="ProcedureImage01" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={Autorefractometer} cardTitle="Autorefractometer and Non Contact Tonometer" className="ProcedureImage02" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={BoylesApparatus} cardTitle="Boyles Apparatus with Pulse oximeter" className="ProcedureImage01" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={HighSpeedVitrectomy} cardTitle="High Speed Vitrectomy Machine" className="ProcedureImage02" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={Lumera} cardTitle="Lumera 1 Surgical Operating Microscope" className="ProcedureImage02" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={OAOptical} cardTitle="OA 2000 Optical Biometer" className="ProcedureImage02" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={SiriusScheimpflug} cardTitle="Sirius Scheimpflug Topographer" className="ProcedureImage01" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <ProcedureCard cardImg={SovereignCompact} cardTitle="Sovereign Compact Machine" className="ProcedureImage02" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Procedure;