// import React from 'react';
import React, { useLayoutEffect } from 'react';
import OurStory from "../../images/award images/Award Image 03.JPG";
import OurAwards from "../../images/award images/Award Image 01.JPG";
import Hospital from '../../images/hospital-vector.png';
import './about.css';
import { MetaTags } from 'react-meta-tags';
const About = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <div>

            <MetaTags>
                <title>Laser Eye Surgery In Khar Mumbai - Eye Life Hospital</title>
                <meta title="Laser Eye Surgery In Khar Mumbai - Eye Life Hospital" />
                <meta
                    name="description"
                    content="Transform your vision with advanced laser eye surgery at Eye Life Hospital in Khar Mumbai. Clarity awaits."
                />
                <meta
                    name="keywords"
                    content="Best Eye Hospital In Khar Mumbai,Cataract surgery In Khar Mumbai,LASIK Surgery in Khar Mumbai,Retina Surgery in Khar Mumbai,Cornea Specialist in Khar Mumbai,Best Eye Doctor in Khar Mumbai,Laser Eye Surgery In Khar Mumbai "
                />
                <link rel="canonical" href="https://eyelifehospital.com/laser-eye-surgery-in-khar-mumbai" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Laser Eye Surgery In Khar Mumbai - Eye Life Hospital" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://eyelifehospital.com/" />
                <meta property="og:description" content="Transform your vision with advanced laser eye surgery at Eye Life Hospital in Khar Mumbai. Clarity awaits." />
                <meta property="og:image" content="https://eyelifehospital.com/static/media/logo-footer.0798714161edb8b2d5b9.png" />
            </MetaTags>

            {/* Banner */}
            <div className='aboutHome'>
                <h1>Laser Eye Surgery In Khar Mumbai</h1>
                {/* <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, voluptas.</h6> */}
            </div>

            {/* Our Story */}
            < div className='ourStory'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='ourStoryText'>
                            <h2>Our Story</h2>
                            <h6>Eye Life,™ eye hospital for personalised eye care, has been created to be the best eye hospital in Mumbai. Located in the heart of Khar-Bandra, on the Linking Road, culture at Eye Life™ revolves around its motto to serve patients. It is staffed and equipped to provide quality eye care including eye lasers and eye surgeries. It is managed by two Ophthalmologists, Dr Gaurav Shah and Dr Sushmita Shah who are trained in premier eye institutions in USA and India.</h6>
                            <h6>We believe that people have unique hurdles related to their eyes and eyesight and that they require tender loving support to heal themselves. We have a team of eye doctors and medical and non medical staff which helps to understand the exact hurdles and provide solutions. We provide personalised Eye Care to jump start the healing process.</h6>
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='ourStoryImg'>
                            <img src={OurAwards} alt='' />
                        </div>
                    </div>
                </div>
            </div >

            {/* mission vission */}
            {/* <div className='missionSection' >
                <div className='row'>
                    <div className='col-md-6 d-flex justify-content-center align-items-center order-2 order-md-1'>
                        <div className='missionImg'>
                            <img src={OurStory} />
                        </div>
                    </div>
                    <div className='col-md-6 order-1 order-md-2'>
                        <div className='missionText'>
                            <h2>Our Sankalp Is</h2>
                            <p>To serve people with eye problem by providing safe and effective eye tretments and surgeries.</p>
                            <p>We will always value your time and the trust that you have besttowed on us.</p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* =================== why us ==================== */}
            <section className="whyChooseUS" id="whyUs">
                <div className="container">
                    <div className="titleSection">
                        <h2>Why Choose Us</h2>
                        <p>
                            Who you trust to correct your vision is one of the most crucial decisions you will have to make.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12 d-flex justify-content-lg-start justify-content-center align-items-center">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 justify-content-center align-items-center mb-5">
                                    <div className="whyUsCard">
                                        <div className="whyUsIcon">
                                            <span className="material-symbols-outlined">safety_check</span>
                                        </div>
                                        <div className="whyUsTitle">
                                            <h3>Full Time Specialist System</h3>
                                        </div>
                                        <div className="whyUsText">
                                            <p>
                                                We  offer a full range of ophthalmology treatments for people of all ages. We are committed to providing you with the most advanced eye care services available today.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 justify-content-center align-items-center mb-5">
                                    <div className="whyUsCard">
                                        <div className="whyUsIcon">
                                            <span className="material-symbols-outlined">biotech</span>
                                        </div>
                                        <div className="whyUsTitle">
                                            <h3>Technology</h3>
                                        </div>
                                        <div className="whyUsText">
                                            <p>
                                                We stay on top of the latest advances in techniques and technology to ensure that you have access to state-of-the-art treatment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center">
                            <div className="whyChooseImg">
                                <img src={Hospital} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 d-flex justify-content-lg-end justify-content-center align-items-center">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-12 justify-content-center align-items-center mb-5">
                                    <div className="whyUsCard">
                                        <div className="whyUsIcon">
                                            <span className="material-symbols-outlined">groups</span>
                                        </div>
                                        <div className="whyUsTitle">
                                            <h3>Experienced Team</h3>
                                        </div>
                                        <div className="whyUsText">
                                            <p>
                                                Our experienced team is not here to sell the latest treatment to you. We are here to provide high-quality care that is personalized to suit your needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-12 justify-content-center align-items-center mb-5">
                                    <div className="whyUsCard">
                                        <div className="whyUsIcon">
                                            <span className="material-symbols-outlined">
                                                volunteer_activism
                                            </span>
                                        </div>
                                        <div className="whyUsTitle">
                                            <h3>Purpose and Passion</h3>
                                        </div>
                                        <div className="whyUsText">
                                            <p>
                                                Every step of the way, we will work with you to keep your vision clear and create a pleasant treatment experience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div >
    )
}

export default About