import React from 'react';
import "./Publications.css"
import { useLayoutEffect } from 'react';

const Publications = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <>

            {/* Home */}
            <div className='Publications-banner'>
                {/* <h2>Publications</h2> */}
            </div>


            <section className='Publications-Section-2'>
                <div className='container'>
                    <div className='Publications-Section-2-heading'>
                        <h3>Peer Reviewed Publications</h3>
                    </div>
                    <div className='Publications-Para'>
                        <h2>Dr. Sushmita Shah </h2>
                        <ul>
                            <li>
                                Shah, Sushmita G 1,4, ; Pandey, Vinita 3 ; Shah, Yogesh C 4 ; Shah, Gaurav Y 2 . Penetrating keratoplasty
                                following Tenon&#39;s patch graft: A clinicopathological correlation. Indian Journal of Ophthalmology -
                                Case Reports 3(1):p 33-34, Jan–Mar 2023. | DOI: 10.4103/ijo.IJO_1098_22.
                            </li>
                            <li>
                                Shah SG, Mishra DK, Shah GY. Amniotic Membrane Grafting for Ocular Surface
                                Inflammation Following Topical Interferon Alpha 2b Therapy. J Interferon
                                Cytokine Res. 2022 Mar;42(3):137-139. doi: 10.1089/jir.2021.0191. PMID:
                                35298286.
                            </li>
                            <li>
                                Shah SG, Shetty R, Ghosh A, Shah GY. Corneal scarring following collagen
                                cross-linking: evidence of increased lysyl oxidase activity. Eur J Ophthalmol.
                                2023 May;33(3):NP23-NP26. doi: 10.1177/11206721221078152. Epub 2022 Feb 7. PMID:
                                35130082.
                            </li>
                            <li>
                                Shah SG, Mishra DK, Shah GY. Oral mucous membrane grafting for unilateral lid
                                margin keratinization following radiotherapy. Orbit. 2023 Feb;42(1):112-115.
                                doi: 10.1080/01676830.2021.1974055. Epub 2021 Sep 6. PMID: 34488524.
                            </li>
                            <li>
                                Shankar S, Shah SG, Yadav S, Chugh A. Novel corneal targeting cell
                                penetrating peptide as an efficient nanocarrier with an effective antimicrobial
                                activity. Eur J Pharm Biopharm. 2021 Sep;166:216-226. doi:

                                10.1016/j.ejpb.2021.06.014. Epub 2021 Jun 30. PMID: 34214635.
                            </li>
                            <li>
                                Rohira H, Shankar S, Yadav S, Shah SG, Chugh A. Enhanced in vivo antifungal
                                activity of novel cell penetrating peptide natamycin conjugate for efficient
                                fungal keratitis management. Int J Pharm. 2021 May 1;600:120484. doi:
                                10.1016/j.ijpharm.2021.120484. Epub 2021 Mar 15. PMID: 33737097.
                            </li>
                            <li>
                                Shah SG, Shah GY. Recurrent late onset diffuse lamellar keratitis. Indian J
                                Ophthalmol. 2020 Dec;68(12):3033-3034. doi: 10.4103/ijo.IJO_1551_20. PMID:
                                33229694; PMCID: PMC7856958.
                            </li>
                            <li>
                                Shah SG, Raichura ND, Parikh K, Sonawane D, Shah GY. Linear scleroderma en
                                coup de sabre in an adult female: Ocular, adnexal and tomographic features.
                                Indian J Ophthalmol. 2020 Nov;68(11):2518-2519. doi: 10.4103/ijo.IJO_525_20.
                                PMID: 33120665; PMCID: PMC7774157.
                            </li>
                            <li>
                                Shah SG, Shah GY. Corneal calcium deposition as a plaque following collagen
                                cross linking and vitamin D insufficiency. Indian J Ophthalmol. 2020
                                Jul;68(7):1441-1442. doi: 10.4103/ijo.IJO_2160_19. PMID: 32587185; PMCID:
                                PMC7574075.
                            </li>
                            <li>
                                Shah SG, Shah SM, Agarwal J, Mulchandani A. Feasibility and outcome of simple
                                limbal epithelial transplantation (SLET) in unilateral total limbal stem cell
                                deficiency (LSCD) following chemical injury, in a semiurban location in Western
                                India. Indian J Ophthalmol. 2019 Aug;67(8):1382-1383. doi:
                                10.4103/ijo.IJO_1697_18. PMID: 31332152; PMCID: PMC6677084.
                            </li>
                            <li>
                                Shah SG, Shah GY. Non-Descemet stripping automated endothelial keratoplasty
                                for post radial keratotomy corneal edema. Indian J Ophthalmol. 2018
                                Sep;66(9):1333-1335. doi: 10.4103/ijo.IJO_1281_17. PMID: 30127163; PMCID:
                                PMC6113808.
                            </li>
                            <li>
                                Shah SG, Parikh VS, Pineda R. Intravitreal Ozurdex&lt;sup&gt;®&lt;/sup&gt; Implantation
                                after Boston Type I Keratoprosthesis. Ocul Immunol Inflamm. 2018;26(3):449-451.
                                doi: 10.1080/09273948.2016.1213858. Epub 2016 Sep 6. PMID: 27599154.
                            </li>
                            <li>
                                Jain A, Shah SG, Chugh A. Cell penetrating peptides as efficient
                                nanocarriers for delivery of antifungal compound, natamycin for the treatment of
                                fungal keratitis. Pharm Res. 2015 Jun;32(6):1920-30. doi:
                                10.1007/s11095-014-1586-x. Epub 2014 Dec 3. PMID: 25467959.
                            </li>
                            <li>
                                Fernandes M, Goyal J, Shah S. Phakic intraocular collamer lens.
                                Ophthalmology. 2012 Jan;119(1):201; author reply 201-2. doi:
                                10.1016/j.ophtha.2011.09.020. PMID: 22214948.
                            </li>
                            <li>
                                Saha D, Fernandes M, Shah SG, Murthy IS. Postoperative risk factors
                                influencing corneal graft survival in the Singapore corneal transplant study. Am
                                J Ophthalmol. 2011 Aug;152(2):322-3; author reply 323. doi:
                                10.1016/j.ajo.2011.04.016. PMID: 21784197.
                            </li>
                            <li>
                                Fernandes M, Moreker MR, Shah SG, Vemuganti GK. Exaggerated subepithelial
                                fibrosis after anterior stromal puncture presenting as a membrane. Cornea. 2011
                                Jun;30(6):660-3. doi: 10.1097/ICO.0b013e3182012703. PMID: 21242782.
                            </li>
                            <li>
                                Basu S, Vaddavalli PK, Ramappa M, Shah S, Murthy SI, Sangwan VS. Intracameral
                                perfluoropropane gas in the treatment of acute corneal hydrops. Ophthalmology.
                                2011 May;118(5):934-9. doi: 10.1016/j.ophtha.2010.09.030. Epub 2011 Jan 6. PMID:
                                21211841.
                            </li>
                            <li>
                                Fernandes M, Reddy P, Shah SG. One-year outcome of bilateral randomised
                                prospective clinical trial comparing photorefractive keratectomy (PRK) with
                                mitomycin C (MMC) and laser in situ keratomileusis (LASIK). Br J Ophthalmol.
                                2011 Mar;95(3):433. doi: 10.1136/bjo.2010.188151. Epub 2010 Aug 23. PMID:

                                20733029.
                            </li>
                            <li>
                                Sachdeva V, Reddy P, Fernandes M, Shah S, Kekunnaya R. Refractive outcomes
                                with secondary intraocular lens implantation in children. J AAPOS. 2010
                                Aug;14(4):377-8. doi: 10.1016/j.jaapos.2010.06.002. Epub 2010 Jul 10. PMID:
                                20621529.
                            </li>
                            <li>
                                Goyal J, Fernandes M, Shah SG. Intracameral voriconazole injection in the
                                treatment of fungal endophthalmitis resulting from keratitis. Am J Ophthalmol.
                                2010 Dec;150(6):939; author reply 939-40. doi: 10.1016/j.ajo.2010.08.009. PMID:
                                21094710.
                            </li>
                            <li>
                                Shah SG, Matalia HP, Sangwan VS. Spontaneous onset corneoscleral hematic
                                cyst. Indian J Ophthalmol. 2006 Jun;54(2):118-20. doi: 10.4103/0301-4738.25834.
                                PMID: 16770030.
                            </li>
                            <li>
                                Shah SG, Sridhar MS, Sangwan VS. Acute corneal hydrops treated by
                                intracameral injection of perfluoropropane (C3F8) gas. Am J Ophthalmol. 2005
                                Feb;139(2):368-70. doi: 10.1016/j.ajo.2004.07.059. PMID: 15734011.
                            </li>
                        </ul>
                    </div>

                    <div className='Publications-Para'>
                        <h2>Dr. Gaurav Shah </h2>
                        <ul>
                            <li>
                                Shah SG, Shetty R, Ghosh A, Shah GY. Corneal scarring following collagen
                                cross-linking: evidence of increased lysyl oxidase activity. Eur J Ophthalmol.
                                2023 May;33(3):NP23-NP26. doi: 10.1177/11206721221078152. Epub 2022 Feb 7. PMID:
                                35130082.
                            </li>
                            <li>
                                Shah SG, Mishra DK, Shah GY. Oral mucous membrane grafting for unilateral lid
                                margin keratinization following radiotherapy. Orbit. 2023 Feb;42(1):112-115.
                                doi: 10.1080/01676830.2021.1974055. Epub 2021 Sep 6. PMID: 34488524.
                            </li>
                            <li>
                                Shah SG, Mishra DK, Shah GY. Amniotic Membrane Grafting for Ocular Surface
                                Inflammation Following Topical Interferon Alpha 2b Therapy. J Interferon
                                Cytokine Res. 2022 Mar;42(3):137-139. doi: 10.1089/jir.2021.0191. PMID:
                                35298286.
                            </li>
                            <li>
                                Shah SG, Shah GY. Recurrent late onset diffuse lamellar keratitis. Indian J
                                Ophthalmol. 2020 Dec;68(12):3033-3034. doi: 10.4103/ijo.IJO_1551_20. PMID:
                                33229694; PMCID: PMC7856958.
                            </li>
                            <li>
                                Shah SG, Raichura ND, Parikh K, Sonawane D, Shah GY. Linear scleroderma en
                                coup de sabre in an adult female: Ocular, adnexal and tomographic features.
                                Indian J Ophthalmol. 2020 Nov;68(11):2518-2519. doi: 10.4103/ijo.IJO_525_20.
                                PMID: 33120665; PMCID: PMC7774157.
                            </li>
                            <li>
                                Shah SG, Shah GY. Corneal calcium deposition as a plaque following collagen
                                cross linking and vitamin D insufficiency. Indian J Ophthalmol. 2020
                                Jul;68(7):1441-1442. doi: 10.4103/ijo.IJO_2160_19. PMID: 32587185; PMCID:
                                PMC7574075.
                            </li>
                            <li>
                                Shah SG, Shah GY. Non-Descemet stripping automated endothelial keratoplasty
                                for post radial keratotomy corneal edema. Indian J Ophthalmol. 2018
                                Sep;66(9):1333-1335. doi: 10.4103/ijo.IJO_1281_17. PMID: 30127163; PMCID:
                                PMC6113808.
                            </li>
                            <li>
                                Pathengay A, Shah GY, Das MK, Khera M. Role of famciclovir in the management
                                of acute retinal necrosis in developing countries. Retin Cases Brief Rep. 2012
                                Fall;6(4):419-22. doi: 10.1097/ICB.0b013e31824f7118. PMID: 25389946.

                            </li>
                            <li>
                                Das MK, Pathengay A, Shah GY, Koday NK. Vancomycin-resistant coagulase
                                negative Staphylococcus endophthalmitis following cataract surgery. J Cataract
                                Refract Surg. 2011 Oct;37(10):1908-9. doi: 10.1016/j.jcrs.2011.07.023. PMID:
                                21852070.
                            </li>
                            <li>
                                Pathengay A, Das MK, Shah GY. Spontaneous bilateral peripapillary,
                                subhyaloid and vitreous hemorrhage with severe anemia secondary to idiopathic
                                thrombocytopenic purpura. Indian J Ophthalmol. 2011 Sep-Oct;59(5):409-11. doi:
                                10.4103/0301-4738.83634. PMID: 21836362; PMCID: PMC3159339.
                            </li>
                            <li>
                                Pathengay A, Mathai A, Shah GY, Ambatipudi S. Intravitreal
                                piperacillin/tazobactam in the management of multidrug-resistant Pseudomonas
                                aeruginosa endophthalmitis. J Cataract Refract Surg. 2010 Dec;36(12):2210-1.
                                doi: 10.1016/j.jcrs.2010.09.013. PMID: 21111328.
                            </li>
                            <li>
                                Shah GY, Ambatipudi S, Fernandes M, Lakshmipathy M, Varma PV, Sachdeva V,
                                Pathengay A. We congratulate the authors for publishing their data on bilateral
                                consecutive intravitreal injections administered in their office. Retina. 2010
                                Sep;30(8):1325-6; author reply 1326-7. doi: 10.1097/IAE.0b013e3181e7985c. PMID:
                                20827150.
                            </li>
                            <li>
                                Ambatipudi S, Goyal J, Shah GY, Pathengay A, Das T, Chalam KV. Novel dyes in
                                intraocular surgery. Am J Ophthalmol. 2010 Sep;150(3):442-3; author reply 443.
                                doi: 10.1016/j.ajo.2010.05.007. PMID: 20728682.
                            </li>
                            <li>
                                Chalam KV, Shah GY, Agarwal S, Gupta SK. Illuminated curved 25-gauge
                                vitrectomy probe for removal of subsclerotomy vitreous in vitreoretinal surgery.
                                Indian J Ophthalmol. 2008 Jul-Aug;56(4):331-4. doi: 10.4103/0301-4738.41420.
                                PMID: 18579998; PMCID: PMC2636179.
                            </li>
                            <li>
                                Chalam KV, Agarwal S, Gupta SK, Shah GY. Recovery of retinal sensitivity
                                after transient branch retinal artery occlusion. Ophthalmic Surg Lasers Imaging.
                                2007 Jul-Aug;38(4):328-9. doi: 10.3928/15428877-20070701-10. PMID: 17674925.
                            </li>
                            <li>
                                Chalam KV, Gupta SK, Shah GY, Agarwal S. Successful management of
                                melanocytoma-associated choroidal neovascularization with photodynamic therapy.
                                Eur J Ophthalmol. 2006 Sep-Oct;16(5):776-8. PMID: 17061238.
                            </li>
                            <li>
                                Chalam KV, Shah GY, Shah VA, Rappaport KD. Choroidal neovascular membrane
                                associated with melanocytoma of the optic nerve. Retina. 2006 Jul-
                                Aug;26(6):703-4. doi: 10.1097/01.iae.0000236487.26594.de. PMID: 16829819.
                            </li>
                            <li>
                                Chalam KV, Jain P, Shah VA, Shah GY. Evaluation of web-based annotation of
                                ophthalmic images for multicentric clinical trials. Indian J Ophthalmol. 2006
                                Jun;54(2):126-9. doi: 10.4103/0301-4738.25838. PMID: 16770034.
                            </li>
                            <li>
                                Shah VA, Shah GY, Chalam KV. Reply to endophthalmitis following 25-gauge
                                vitrectomy. Eye (Lond). 2006 Jun;20(6):735-6. doi: 10.1038/sj.eye.6701981. Epub
                                2005 Jun 17. PMID: 15999130.
                            </li>
                            <li>
                                Pathengay A, Shah GY, Das T, Sharma S. Intravitreal triamcinolone acetonide
                                in the management of exogenous bacterial endophthalmitis. Am J Ophthalmol. 2006
                                May;141(5):938-40. doi: 10.1016/j.ajo.2005.11.043. PMID: 16678510.
                            </li>
                            <li>
                                Pathengay A, Shah GY, Das T, Sharma S. Curvularia lunata endophthalmitis
                                presenting with a posterior capsular plaque. Indian J Ophthalmol. 2006
                                Mar;54(1):65-6. doi: 10.4103/0301-4738.21625. PMID: 16531680.
                            </li>
                            <li>
                                Chalam KV, Gupta SK, Shah GY, Agarwal S. Successful management of
                                melanocytomaassociated choroidal neovascularization with photodynamic therapy.
                                Eur J Ophthalmol. 2006 Sep-Oct 2006;16(5):776-778. doi: 10.5301/EJO.2008.3495.
                                PMID: 28221632.

                            </li>
                            <li>
                                Chalam KV, Shah GY, Shah VA. Vitrectomy with or without arteriovenous
                                adventitial sheathotomy for macular edema associated with branch retinal vein
                                occlusion. Am J Ophthalmol. 2005 Jun;139(6):1146; author reply 1146-7. doi:
                                10.1016/j.ajo.2005.02.024. PMID: 15953467.
                            </li>
                            <li>
                                Shah GY, Pathengay A, Mathai A, Savitri S. Bilateral submacular abscess
                                after induced abortion. Indian J Ophthalmol. 2004 Sep;52(3):253-4. PMID:
                                15510471.
                            </li>
                            <li>
                                Natarajan R, Shah GY, Rao SK, Padamanabhan P. Penetrating keratoplasty as a
                                globe-saving procedure in fragile cornea. Cornea. 2003 Mar;22(2):164-5. doi:
                                10.1097/00003226-200303000-00017. PMID: 12605054.
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Publications