import React from 'react';
import './downEnquery.css';

const downEnquiry = () => {
    return (
        <div>
            <div className="down_enquiry">
                <p className="enq1">
                    <a href="tel:996-726-2640" target="_blank">
                        <i className="fa fa-phone" /> &nbsp;Tap To Call{" "}
                    </a>
                </p>
                <p className="enq">
                    <a
                        href="https://wa.me/+91 9967262640?text="
                        className="social-icon whatsapp"
                        target="_blank">
                        <i className="fa fa-whatsapp" /> &nbsp;Whatsapp
                    </a>
                </p>

                <p className="enq2">
                    <a href="mailto: eyelifehosaital@gmail.com" target="_blank">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i> &nbsp;Contact Us
                    </a>
                </p>
            </div>

        </div>
    )
}

export default downEnquiry