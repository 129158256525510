import React from "react";
import "./contactSection.css";

const contactSection = () => {
  return (
    <div>
      <div className="contact">
        <div className="titleSection">
          <h2>Contact Us</h2>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde,
            consequatur! Tenetur, praesentium distinctio! Possimus, facilis
          </p> */}
        </div>
        <div className="row">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="email">
                <i className="fa fa-phone"></i>
                <h4>Phone No:</h4>
                <p><span><a href="tel:996-726-2640">+91-9967262640</a></span></p>
              </div>
              <div className="email" style={{ marginBottom: "20px" }}>
                <i className="fa fa-envelope"></i>
                <h4>Mail ID:</h4>
                <a href="mailto: eyelifehospital@gmail.com">
                  <p>eyelifehospital@gmail.com</p>
                </a>
              </div>
              <div className="email">
                <i className="fa fa-location-arrow"></i>
                <h4>EYE LIFE :</h4>
                <span>
                  2nd Floor, Ram Krishna Chambers, <br></br>Linking Road, Near Ram Krishna Bus Stop and South Avenue Signal, Khar (West), Mumbai: 400052
                </span>
              </div>
              <div className="email socialList mt-3">
                <i className="fa fa-link"></i>
                <h4>Social Media:</h4>
                <ul>
                  <li className="ContactIcons">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/eyelifehosp"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/eyelifehospital/"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/eye-life-eye-hospital/about/"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@eyelifehospital6325"
                    >
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form
              action="php_file/mail.php"
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="FormHeading">
                <h2>send us your query :</h2>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="contName"
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="contEmail"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="name">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="contSubject"
                  required
                />
              </div>
              <div className="form-group">
                <label for="name">Query</label>
                <textarea
                  className="form-control"
                  name="message"
                  id="contMessage"
                  rows="6"
                  required
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  id="sendcontactus"
                  onClick="sendContact();"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default contactSection;
