import { React, useState, useEffect, useRef } from 'react';
import "./navbar.css";
import logo from "../../images/logo-footer.png";
import { NavLink, Link } from "react-router-dom";
import ConditionData from "../PatientInfo/ConditionData.jsx";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ServcieData from '../ServiceData/ServiceData';
const NavBarComponent = () => {
    const navLinkStyle = ({ isActive }) => {
        return {
            color: isActive ? '#0171BD' : 'rgb(0, 179, 255)',
        };
    };

    const [color, setColor] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navRef = useRef();

    const changeColor = () => {
        if (window.scrollY >= 50) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeColor);

        return () => {
            window.removeEventListener('scroll', changeColor);
        };
    }, []);

    const handleNavToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsNavOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const [eventData, setEventData] = useState(ConditionData);
    const [dataId, setDataId] = useState("");

    const handleConditionClick = (index) => {
        setDataId(index.id);
        console.log(index.id);
    };

    return (
        <div className={color ? "headerSection navbarScroll" : "headerSection"}>
            <div className="socialMeidaAndContact">
                <div className="container">
                    <div className="headerContact">
                        <ul>
                            <li>
                                <a href="tel:+919967262640">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <span>+91-9967-262640</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="headerSocial">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/eyelifehosp" target="_blank">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/eyelifehospital?igshid=YmMyMTA2M2Y=" target="_blank">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/eye-life-eye-hospital/" target="_blank">
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/@eyelifehospital6325" target="_blank">
                                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home"><Link to="/"><a className="navbar-brand" href="logo"><img src={logo} alt="logo" /></a></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="">
                            <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="/laser-eye-surgery-in-khar-mumbai"><Link to="/laser-eye-surgery-in-khar-mumbai">About Us</Link></Nav.Link>
                            <Nav.Link href="/best-eye-doctor-in-khar-mumbai"><Link to="/best-eye-doctor-in-khar-mumbai">Doctors</Link></Nav.Link>
                            <button className="dropdownShow">
                                Services
                                <div className="dorpdown"  id="style-1">
                                    <div className="dropdown_list">
                                        {
                                            ServcieData.map((ele) => {
                                                const { title, Slugs } = ele
                                                return (
                                                    <>
                                                        <Nav.Link href={Slugs}>
                                                            <Link to={Slugs}>{title}</Link>
                                                        </Nav.Link>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </button>

                            <Nav.Link href="/eye-treatment-in-khar-mumbai"><Link to="/eye-treatment-in-khar-mumbai">Eye Procedures</Link></Nav.Link>
                            <Nav.Link href="/testimonals"><Link to="/testimonals">Testimonals</Link></Nav.Link>
                            <Nav.Link href="/gallery"><Link to="/gallery">Gallery</Link></Nav.Link>
                            <Nav.Link href="/Publications"><Link to="/Publications">Publications</Link></Nav.Link>
                            <Nav.Link href="/contact"><Link to="/contact">Contact Us</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default NavBarComponent;
