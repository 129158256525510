import React from 'react';
import './procedureCard.css';
import { useLayoutEffect } from 'react';

const ProcedureCard = props => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

  return (
    <div>
        <div className='procedureCard'>
            <div className="row">
                <div className="col-md-12">
                    <div className="procedureCardImg">
                        <img src={props.cardImg} alt="" className={props.className}/>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="procedureCardText">
                        <h2>{props.cardTitle}</h2>
                        <p>{props.cardDesc}</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default ProcedureCard;
