import "./gallery.css";
import React, { useState, useLayoutEffect } from 'react';


import { ImCross } from 'react-icons/im';

// Images

import GalleryImg1 from "../../images/gal1.JPG";
import GalleryImg2 from "../../images/gal2.JPG";
import GalleryImg3 from "../../images/gal3.JPG";
import GalleryImg4 from "../../images/gal4.JPG";
import GalleryImg5 from "../../images/gal5.JPG";
import GalleryImg6 from "../../images/gal6.JPG";
import GalleryImg8 from "../../images/gal8.JPG";
import GalleryImg9 from "../../images/gal9.JPG";
import GalleryImg10 from "../../images/gal10.JPG";
import GalleryImg11 from "../../images/gal11.JPG";

import Award01 from "../../images/award images/Award Image 04.jpeg";
import Award02 from "../../images/award images/Award Image 01.JPG";
import Award03 from "../../images/award images/Award Image 02.jpeg";
import Award04 from "../../images/award images/Award Image 03.JPG";
import Award05 from "../../images/award images/IMG_1206.JPG";
import Award06 from "../../images/award images/IMG_5388.jpeg";
import Award07 from "../../images/award images/mam  Award.JPG";

import AddImage01 from "../../images/AddImages/Chief Guest.JPG";
import AddImage02 from "../../images/AddImages/Counslling Room.JPG";
import AddImage03 from "../../images/AddImages/DSC_1396.JPG";
import AddImage04 from "../../images/AddImages/DSC_1397.JPG";
import AddImage05 from "../../images/AddImages/DSC_1403.JPG";
import AddImage06 from "../../images/AddImages/DSC_1413.JPG";
import AddImage07 from "../../images/AddImages/Laser.JPG";
import AddImage08 from "../../images/AddImages/OCT & Sirius.JPG";
import AddImage09 from "../../images/AddImages/OCT & Sirus.JPG";
import AddImage10 from "../../images/AddImages/OPD Seeing Patiant 1.JPG";
import AddImage11 from "../../images/AddImages/OPD Seeing Patiant 3.JPG";
import AddImage12 from "../../images/AddImages/OT.JPG";
import AddImage13 from "../../images/AddImages/Trophies & Plaques.jpeg";
import AddImage14 from "../../images/AddImages/with Dr . Lahane.JPG";

import certificate1 from "../../images/certificate/certificate1.webp";
import certificate2 from "../../images/certificate/certificate2.webp";
import certificate3 from "../../images/certificate/certificate3.webp";
import certificate4 from "../../images/certificate/certificate4.webp";
import certificate5 from "../../images/certificate/certificate5.webp";
import certificate6 from "../../images/certificate/certificate6.webp";
import certificate7 from "../../images/certificate/certificate7.webp";
import certificate8 from "../../images/certificate/certificate8.webp";
import certificate9 from "../../images/certificate/certificate9.webp";
import certificate10 from "../../images/certificate/certificate10.webp";
import certificate11 from "../../images/certificate/certificate11.webp";
import certificate12 from "../../images/certificate/certificate12.webp";
import certificate13 from "../../images/certificate/certificate13.webp";
import certificate14 from "../../images/certificate/certificate14.webp";
import certificate15 from "../../images/certificate/certificate15.webp";
import certificate16 from "../../images/certificate/certificate16.webp";
import certificate17 from "../../images/certificate/certificate17.webp";
import certificate18 from "../../images/certificate/certificate18.webp";
import certificate19 from "../../images/certificate/certificate19.webp";
import certificate20 from "../../images/certificate/certificate20.webp";
import certificate21 from "../../images/certificate/certificate21.webp";
import certificate22 from "../../images/certificate/certificate22.webp";
import certificate23 from "../../images/certificate/certificate23.webp";
import certificate24 from "../../images/certificate/certificate24.webp";
import certificate25 from "../../images/certificate/certificate25.webp";
import certificate26 from "../../images/certificate/certificate26.webp";
import certificate27 from "../../images/certificate/certificate27.webp";
import certificate28 from "../../images/certificate/certificate28.webp";
import certificate29 from "../../images/certificate/certificate29.webp";
import certificate30 from "../../images/certificate/certificate30.webp";
import certificate31 from "../../images/certificate/certificate31.webp";
import certificate32 from "../../images/certificate/certificate32.webp";
import certificate33 from "../../images/certificate/certificate33.webp";
import certificate34 from "../../images/certificate/certificate34.webp";
import certificate35 from "../../images/certificate/certificate35.webp";
import certificate36 from "../../images/certificate/certificate36.webp";
import certificate37 from "../../images/certificate/certificate37.webp";
import certificate38 from "../../images/certificate/certificate38.webp";




const Gallery = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    document.querySelectorAll('.Gallery-Images img').forEach(image => {
        image.onClick = () => {
            document.querySelector('.Gallery-Images').style.display = "block";
        }
    });
    return (
        <>

            {/* Gallery Home*/}

            <section className="gallerySection">
                <h2>Gallery</h2>
            </section>

            {/* gallery images */}

            <section className="Gallery-Container">
                <div className="row">


                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate1} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate2} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate3} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate4} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate5} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate6} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate7} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate8} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate9} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate10} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate11} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate12} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate13} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate14} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate15} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate16} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate17} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate18} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate19} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate20} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate21} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate22} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate23} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate24} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate25} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate26} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate27} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate28} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate29} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate30} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate31} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate32} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate33} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate34} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate35} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate36} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate37} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={certificate38} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award01} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award02} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award03} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award04} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award05} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award06} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={Award07} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg1} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg2} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg3} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg4} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg5} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg6} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg8} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg9} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg10} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={GalleryImg11} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage01} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage02} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage03} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage04} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage05} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage06} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage07} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage08} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage09} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage10} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage11} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage12} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage13} alt="" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="Gallery-Images">
                            <img src={AddImage14} alt="" />
                        </div>
                    </div>
                </div>

            </section>



        </>
    )
}

export default Gallery; 