import React, { useLayoutEffect } from 'react';
import doctorImg from '../../images/dr-gaurav-shah.JPG';


const DrGauravShah = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <>


            <div className='doctorHome'>
                <h2>Dr. Gaurav Shah</h2>
            </div>
            <div className='container'>
                <div className='doctorDetail-con'>
                    <div className='doctorImage'>
                        <img src={doctorImg} />
                    </div>
                    <div className='doctorText'>
                        <h2>Dr. Gaurav Shah</h2>
                        <h6>Dr. Gaurav Shah is Director, Eye Life and specialises in advanced cataract surgery and management of retina, uveits and glaucoma diseases of the eye. After his Ophthalmology training from the prestigious eye institute, Sankara Nethralaya, in Chennai, he did Retina Fellowship at L V Prasad Eye Institute in Hyderabad and then from University of Florida, USA. He has been a Visiting Assistant Professor in University of Florida for 11 years and has worked as Consultant in Cataract and Retina Services at L V Prasad Eye Institute and Lilavati Hospital, Bandra. </h6>
                        <h6>His areas of interest include macular diseases, vascular retinopathies, retinal detachment, endophthalmitis, uveitis & management of complicated cataract surgery. </h6>
                    </div>
                    <div className='doctorText'>
                        <h5>He is an expert in:</h5>
                        <h4>Treatment of Retinal Diseases</h4>
                        <ul>
                            <li>Retinal detachments including recurrent & complicated cases with GRT, PVR</li>
                            <li>Diabetic vitrectomies for tractional and combined retinal detachments </li>
                            <li>Sutureless vitrectomies</li>
                            <li>Vascular retinopathies such as DR, CRVO, BRVO, ROP, FEVR, PFT</li>
                            <li>Macular diseases - Macular holes, ERM, VMT, CNVM - ARMD, CSR & CME</li>
                            <li>Endophthalmit</li>
                        </ul>
                        <h4>Management of Ocular trauma, Uveitis and Neuro-ophthalmology diseases</h4>
                        <h4>Advanced Management of Cataract</h4>
                        <ul>
                            <li>EDOF, Multifocal, Toric, Aspheric Intraocular lenses</li>
                            <li>Scleral fixated lenses in absence of capsular support</li>
                            <li>Subluxated and dislocated cataract or IOL</li>
                            <li>Cataract with diabetic retinopathy, uveitis or glauco</li>
                        </ul>

                        <p>He has 25 peer-reviewed publications in Indian and International Journals and has written 3 chapters in medical textbooks. He has given multiple invited talks and has performed live retinal surgeries in medical conferences. He is a recipient of Dean’s Research Award – 2005, University of Florida. 1st Prize at the Jacksonville - University of Florida’s Research Day 2005.  </p>
                        <p>He has been a teacher for cataract and retina to many students and is passionate about educating people about how to take care of their eyes.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DrGauravShah