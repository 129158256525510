import React, { useState } from 'react';
import './testimonal.css';
import ReviewCard from '../ReviewCard/reviewCard.jsx';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// images
import GoogleLogo from "../../images/google-reviews-logo.png";
import PractoLogo from "../../images/practo-logo.jpg";
import JustDialLogo from "../../images/JustDial_Logo.png";
import YoutubeLogo from "../../images/youtube-logo.png";
import googleRating from '../../images/customer-reviews.jpg';
import TestimonalVector from "../../images/male-vector.jpg";
import CrDutta from '../../images/crDatta.png';
import crDhanraj from '../../images/crDhanraj.png';
import crDilip from '../../images/crDilip.png';
import crDon from '../../images/crDon.png';
import crEvona from '../../images/crEvona.png';
import CrHutoxiImg from '../../images/CrHutoxiImg.png';
import crPrasad from '../../images/crPrasad.png';
import crRaksha from '../../images/crRaksha.png';
import crSudhir from '../../images/crSudhir.png';
import crSushant from '../../images/crSushant.png';
import crUsha from '../../images/crUsha.png';
import crVidhyahar from '../../images/crVidhyahar.png';
import { useLayoutEffect } from 'react';


const Testiomnal = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <div>

            {/* Home */}
            <div className='testimonalHome'>
                {/* <h2>Testimonals</h2> */}
            </div>

            {/* youtube testimonal */}
            <div className='youtubeReview'>
                <div className='googleReview'>
                    <div className='youtubeLogo'>
                        <img src={YoutubeLogo} />
                    </div>
                    <div className='googleReviewInner'>
                        <div className='googleReviewCards'>
                            <Swiper navigation={true}
                                slidesPerView={1}
                                spaceBetween={30}
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[FreeMode, Pagination, Navigation]}
                                className="mySwiper">
                                <SwiperSlide>
                                    <div className='youtubeFrame'>
                                        <iframe src="https://www.youtube.com/embed/fSbQcDVp4UI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame'>
                                        <iframe src="https://www.youtube.com/embed/Z4359bHkr3k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

            {/* Google */}
            <div className='googleReview mt-4'>
                <div className='googleReviewInner'>
                    <div className='googleLogo'>
                        <img src={googleRating} />
                    </div>
                    <div className='googleReviewCards'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2800,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper">
                            <SwiperSlide>
                                <ReviewCard testimonalName="Evona Fernandes" testimonalText="Both Doctors, Dr Gaurav and Dr Sushmita are very accommodating and patient. They were really understanding and gave my Dad good eye treatment and care, both pre and post Surgery. Post the Cataract Surgery, my Dad can see much better than before, all thanks to God and also our heartfelt thanks to Dr Gaurav and Dr Sushmita....I would certainly recommend Eye Life" testimonalImg={crEvona} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Hutoxi Dumasia" testimonalText="Had  visited Doc Gaurav and Doc Sushmita Shah. Each excels in their respective field. My husband had problem with his eyes, which was solved to quite some extent. We are very pleased with their treatment.
A MUST visit for people suffering with eye problems. Both husband and wife are excellent, patient and congenial.Besides, the staff is very helpful." testimonalImg={CrHutoxiImg} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Sushant Kumar Padhi" testimonalText="Very highly skilled surgeons doctors with state of the art facilities with meticulous post operation care and follow up .👍🙏" testimonalImg={crSushant} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Vidyadhar Mahadgut" testimonalText="Best eye hospital for eye care. Dr. Gaurav Shah and Dr. Sushmita Shah are very helpful. They treat the patient as a family member and render excellent service by understanding problem very well. We are highly impressed and grateful as they treated my wife's glaucoma problem. We are very happy and satisfied. There staff also cooperative and excellent. Very clean and well presented hospital with good service." testimonalImg={crVidhyahar} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Dhanraj Poojari" testimonalText="Dr Sushmita Shah has cured my chronic stye problem. I have been suffering for many years with repeated styes but could not find out why is it happening until I went to Eye Life.
I have been to many doctors all over the world and they could not diagnose my condition. Dr Sushmita is a superb doctor, funny too, a healer and very caring.
I highly recommend her and am so impressed with her that I plan to get my LASIK done with her next month.
Kudos to Dr Sushmita & Team Eye Life!" testimonalImg={crDhanraj} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Prasad Chaubal" testimonalText="Eye Life is one of the best hospitals in Mumbai for eye care and related surgeries. I got my father operated by Dr Gaurav Shah who is very professional, soft spoken and calm. He explained us all the things really well, detailed out the treatment options and explained the Pros and Cons of each option. He made us very comfortable during the entire process right from cataract detection to surgery and post surgery care. Also the non medical staff is very polite and professional. The facility is very good and conveniently located on link road in Khar West Mumbai. If I ever need the eye care expertise, I will be happy to seek help from Eye Life again. Would also recommend it to my friends and family. Thank you! Thank you! Thank you!
" testimonalImg={crPrasad} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Dilip Raut" testimonalText="It was a realy a memorable experience for Cataract Surgery of both my eyes within a span of three days ( First on Friday & Second one was on Monday! ) The expertise and confidence level of Dr Gaurav Shah was excellent!!! His long experience in the field ( In India & USA) has made both surgery flawless ! The whole team was having synergic actions ! All are very co-operative. The infrastructure was very good. The additional Opthalmologist was an asset.  " testimonalImg={crDilip} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Raksha Shetty" testimonalText="Had an excellent experience coming to Dr Shah's, for treatment for a trauma to the eye. He came back after having just left for the day, and stayed on for an hour after to make sure he was able to check the eye properly and suggest line of treatment. Clinic too very well managed and sanitised." testimonalImg={crRaksha} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Datta Masurkar" testimonalText="Doctor is very kind person. He had catract surgery of my both the eyes successfully and guide us ahot to do after surgery.  Staff is very co operative.  I would request needy to take treatment from eye life hospital." testimonalImg={CrDutta} />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            {/* practo */}
            <div className='googleReview practo'>
                <div className='googleReviewInner'>
                    <div className='practoLogo'>
                        <img src={PractoLogo} />
                    </div>
                    <div className='googleReviewCards'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper">
                            <SwiperSlide>
                                <ReviewCard testimonalName="Deep Dholakia  (Verified)" testimonalText="
Dr. Sushmita Shah is very friendly and easily accessible - she briefed about the whole surgery in advance, what precautions to be taken etc. - Gave enough time, addressed all our queries and concerns and got calls after the operation to check if everything is okay - very good pre and post surgery care. She specially came on Sundays despite being a holiday to check the eyes before and after the operation which is very much appreciated. A very competent doctor besides a great person to deal with." testimonalImg={TestimonalVector} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Raendra Katkar (Verified)" testimonalText="I am being treated by Dr Gaurav Shah for 2 to 4 years on Retina and recently on Cataract. I am absolutely satisfied and happy the way my eye problems are successfully treated by Dr. Gaurav Shah. His way of diagnosing the problem, identifying the options of treatment, suggesting the best suitable option and then successfully treating the problem impressed me." testimonalImg={TestimonalVector} />

                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Dhanraj Poojari  (Verified)" testimonalText="Dr Sushmita Shah has cured my chronic stye problem. I have been suffering for many years with repeated styes but could not find out why is it happening until I went to Eye Life.

I have been to many doctors all over the world and they could not diagnose my condition.

Dr Sushmita is a superb doctor, funny too, a healer and very caring. I highly recommend her and am so impressed with her that I plan to get my LASIK done with her next month.

Kudos to Dr Sushmita & Team Eye Life!" testimonalImg={TestimonalVector} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Suresh Laxman Mundukur  (Verified)" testimonalText="Dr. Gaurav Shah is a young down to earth person having no airs about him. Puts his patients at ease before starting the check up and treatment, so are his staff. Found it worthwhile the time spent with him and his staff. Will surely recommend to my friend's and in family circles." testimonalImg={TestimonalVector} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ReviewCard testimonalName="Sagar Gurav  (Verified)" testimonalText="Good experience very friendly nature

Taking good personal care of pts

Having good clinic

Staff also is taking good once enter in the clinic." testimonalImg={TestimonalVector} />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            {/* just dial */}
            {/* <div className='googleReview'>
                <div className='googleReviewInner'>
                    <div className='jdLogo '>
                        <img src={JustDialLogo} />
                    </div>
                    <div className='googleReviewCards'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper">
                            <SwiperSlide><ReviewCard /></SwiperSlide>
                            <SwiperSlide><ReviewCard /></SwiperSlide>
                            <SwiperSlide><ReviewCard /></SwiperSlide>
                            <SwiperSlide><ReviewCard /></SwiperSlide>
                            <SwiperSlide><ReviewCard /></SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default Testiomnal;