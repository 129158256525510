import React, { useState, useLayoutEffect } from 'react';
import './doctorDetail.css';
import doctorImg from '../../images/dr-gaurav-shah.JPG';
import DrSushmitaImage from "../../images/IMG_8229.jpg";
import { MetaTags } from 'react-meta-tags';

const DoctorDetail = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <div>
            {/* banner */}

            <MetaTags>
                <title>Best Eye Doctor In Khar Mumbai - Eye Life Hospital</title>
                <meta title="Best Eye Doctor In Khar Mumbai - Eye Life Hospital" />
                <meta
                    name="description"
                    content="Experience World-Class Eye Care with the Best Eye Doctor in Khar, Mumbai. Clearer Vision Awaits!"
                />
                <meta
                    name="keywords"
                    content="Best Eye Hospital In Khar Mumbai,Cataract surgery In Khar Mumbai,LASIK Surgery in Khar Mumbai,Retina Surgery in Khar Mumbai,Cornea Specialist in Khar Mumbai,Best Eye Doctor in Khar Mumbai,Laser Eye Surgery In Khar Mumbai "
                />
                <link rel="canonical" href="https://eyelifehospital.com/best-eye-doctor-in-khar-mumbai" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Best Eye Doctor In Khar Mumbai - Eye Life Hospital" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://eyelifehospital.com/" />
                <meta property="og:description" content="Experience World-Class Eye Care with the Best Eye Doctor in Khar, Mumbai. Clearer Vision Awaits!" />
                <meta property="og:image" content="https://eyelifehospital.com/static/media/logo-footer.0798714161edb8b2d5b9.png" />
            </MetaTags>

            <div className='doctorHome'>
                <h1>Best Eye Doctor In Khar Mumbai</h1>
            </div>

            <div className='container'>

                <div className='doctorDetail-con'>
                    <div className='doctorImage'>
                        <img src={DrSushmitaImage} alt="Dr. Sushmita Shah" />
                    </div>
                    <div className='doctorText'>
                        <h2>Dr. Sushmita Shah </h2>
                        <h6>Dr. Sushmita Shah is Director, Eye Life and Senior Consultant in Cataract, Lasik, Cornea, Dry Eye, Ocular Surface, and Keratoconus Services. She specialises in corneal surgeries such as corneal transplant beside being an expert in cataract surgery and refractive surgery for removal of spectacle number such as LASIK. </h6>
                        <h6>She is trained from some of the best academic institutes in India and Abroad. Following her Ophthalmology training from the prestigious eye institute, Sankara Nethralaya, in Chennai, she has done Cornea and Anterior Segment fellowship at L V Prasad Eye Institute in Hyderabad. Dr. Sushmita Shah has specialised in cornea and refractive surgery from Mass Eye and Ear at the esteemed Harvard Medical School in USA. </h6>
                        <h6>She has been a Consultant in Cataract and Retina Services at L V Prasad Eye Institute and Lilavati Hospital, Bandra.</h6>
                    </div>
                    <div className='doctorText'>
                        <h5>She is an expert in:</h5>
                        <ul>
                            <li>Lamellar corneal transplants – DSEK, DALK</li>
                            <li>Keratoconus management – Collagen Cross Linking, INTACS, Specialized contact lenses</li>
                            <li>Anterior Segment Reconstruction – Iridodialysis repair, Pupilloplasty</li>
                            <li>Boston keratoprosthesis, Temporary keratoprosthesis</li>
                            <li>Ocular surface Reconstruction – SLET, Amniotic membrane grafting, Tenonplasty, Pterygium</li>
                            <li>Penetrating keratoplasty – Optical, Therapeutic</li>
                            <li>Microbial Keratitis management including microbiology</li>
                            <li>Cataract surgery in compromised cornea and ocular surface</li>
                            <li>Refractive surgery – LASIK, PRK</li>
                            <li>Management of Ocular Trauma</li>
                            <li>Management of Dry eyes, Computer Vision Syndrome</li>
                            <li>Medical management of corneal and ocular surface diseases</li>
                        </ul>
                        <p>She has more than 21 peer-reviewed publications in national and international journals through 2023. She is reviewer for Indian Journal of Ophthalmology, Journal of ocular pharmacology and therapeutics, and Journal of Clinical Ophthalmology and Research. She is on the prestigious editorial board of the Indian Journal of Ophthalmology.</p>
                        <p>She is a member of All India Ophthalmological Society, Bombay Ophthalmologist’s Association, Maharashtra Ophthalmological Society, Cornea Society, India, and Ophthalmological Society of West Bengal.</p>
                        <h5>She has numerous awards to her credit including:</h5>
                        <ul>
                            <li>Awarded Research Grant by Department fof Biotechnology, Government of India for Collaborative Research project with IIT, Delhi in 2012.</li>
                            <li>Awarded Best Free Paper Award at Keracon 2014, Annual Meeting of Cornea Society of India, held at Mahabalipuram, Chennai.</li>
                            <li>Awarded Dr M C Luthra Gold Medal for Best Paper at Uttaraeyecon 2014, Annual Meeting of Uttarakhand State Ophthalmological Society.</li>
                            <li>Awarded Second Prize for Young ophthamologist’s for Scientific Video on “Management of Pterygium” at the Annual Meeting of the European Society of Cataract and Refractive Surgeons, at Stockholm, Sweden in September 2007</li>
                            <li>Awarded “Honorable Mention” for scientific poster on “Intracameralperfluoropropane gas for the treatment of Acute Cornea Hydrops” at the World Cornea Congress, at Washington DC, April 2005.</li>
                            <li>Awarded International Travel Grant by Sir Dorabji Tata Trust, Mumbai for poster presentation at World Cornea Congress, 2005 at Washington DC.</li>
                            <li>Awarded Second Prize for Innovation for Scientific Video on “Intracameralperfluoropropane gas for the treatment of Acute Cornea Hydrops” at the Annual Meeting of the European Society of Cataract and Refractive Surgeons, September 2005.</li>
                        </ul>
                    </div>
                </div>

                <div className='doctorDetail-con'>
                    <div className='doctorImage'>
                        <img src={doctorImg} />
                    </div>
                    <div className='doctorText'>
                        <h2>Dr. Gaurav Shah</h2>
                        <h6>Dr. Gaurav Shah is Director, Eye Life and specialises in advanced cataract surgery and management of retina, uveits and glaucoma diseases of the eye. After his Ophthalmology training from the prestigious eye institute, Sankara Nethralaya, in Chennai, he did Retina Fellowship at L V Prasad Eye Institute in Hyderabad and then from University of Florida, USA. He has been a Visiting Assistant Professor in University of Florida for 11 years and has worked as Consultant in Cataract and Retina Services at L V Prasad Eye Institute and Lilavati Hospital, Bandra. </h6>
                        <h6>His areas of interest include macular diseases, vascular retinopathies, retinal detachment, endophthalmitis, uveitis & management of complicated cataract surgery. </h6>
                    </div>
                    <div className='doctorText'>
                        <h5>He is an expert in:</h5>
                        <h4>Treatment of Retinal Diseases</h4>
                        <ul>
                            <li>Retinal detachments including recurrent & complicated cases with GRT, PVR</li>
                            <li>Diabetic vitrectomy for tractional and combined retinal detachments </li>
                            <li>Sutureless vitrectomy</li>
                            <li>Vascular retinopathy such as DR, CRVO, BRVO, ROP, FEVR, PFT</li>
                            <li>Macular diseases - Macular holes, ERM, VMT, CNVM - ARMD, CSR & CME</li>
                            <li>Endophthalmitis</li>
                        </ul>
                        <h4>Management of Ocular trauma, Uveitis and Neuro-ophthalmology diseases</h4>
                        <h4>Advanced Management of Cataract</h4>
                        <ul>
                            <li>EDOF, Multifocal, Toric, Aspheric Intraocular lenses</li>
                            <li>Scleral fixated lenses in absence of capsular support</li>
                            <li>Subluxated and dislocated cataract or IOL</li>
                            <li>Cataract with diabetic retinopathy, uveitis or glauco</li>
                        </ul>

                        <p>He has 25 peer-reviewed publications in Indian and International Journals and has written 3 chapters in medical textbooks. He has given multiple invited talks and has performed live retinal surgeries in medical conferences. He is a recipient of Dean’s Research Award – 2005, University of Florida. 1st Prize at the Jacksonville - University of Florida’s Research Day 2005.  </p>
                        <p>He has been a teacher for cataract and retina to many students and is passionate about educating people about how to take care of their eyes.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DoctorDetail;