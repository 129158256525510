import Cornea from "../../images/Cornea.jpg";
import Glaucoma from "../../images/Glaucoma.jpg";
import LasikDustbin from "../../images/lasik-throw-glass.jpg";
import Oculoplasty from "../../images/oculoplasty.png";
import PedOphthalmolgy from "../../images/PedOphthalmolgy.jpg";
import Retina from "../../images/Retinal.jpg";


const ConditionData = [
    {
        id: 0,
        ConditionName: 'Cornea',
        ConditionImage: Cornea,
        Title1: "RED EYE",
        Description11: "Red eye is most commonly is due to Conjunctivitis which can be due to infection or allergy.",
        Description12: "Red eye with other symptoms like pain, loss of vision etc. could be due to more serious eye disease and requires immediate consultation with eye doctor.",
        Title2: "DRY EYES",
        Description21: "Dry eyes may occur due to decreased tear production, abnormal tear film, increased exposure to electronic gadgets and in many other conditions. Simple dry eyes can be treated by limiting screen exposure and taking regular breaks, using artificial tears and by following the simple 20-20-20 rule.",
        Title3: "OCULAR TRAUMA",
        Description31: "Ocular trauma or injury to the eye is an emergency. It can happen due to any blunt or sharp object hitting the eye or due to exposure to any acid, lime or chuna or any chemical. Very often young children get injured and are often scared to open their eyes. Timely checkup and initiation of treatment is very important.",
        Title4: "EYE ALLERGY",
        Description41: "Eye allergy causes red eye with itching, watering, discomfort. Often, the cause of allergy is unknown. Applying ice cubes or cool eye packs often relieves allergy. It is also important to keep your house clean and dust free and consult doctor during exacerbations.",
        Title5: "KERATOCONUS",
        Description51: "Disease in which the Cornea or the front layer of the eye changes its shape and becomes conical leading to progressive decrease in vision and it usually affects both eyes. Common symptoms include frequent change of glasses, inability to see clearly with glasses etc. Eye check and Topography tests are important to make a diagnosis. Can be treated easily with collagen cross linking, lasers, contact lens etc if treated early.",
        Title6: "PTERYGIUM",
        Description61: "Pterygium is a fleshy mass which can cover the Cornea to make it irregular and cause decrease of vision. It is usually caused by excessive exposure to sunlight. Pterygium which is increasing in size should be removed by surgery.",
        Title7: "CORNEAL EDEMA",
        Description71: "Corneal edema is a condition in which fluid enters into the cornea causing loss of corneal clarity and loss of vision. It is mostly seen after cataract surgery or following corneal infections and can often be treated with appropriate drops. Severe cases may be treated with advanced sutureless corneal transplantation procedure.",
        Title8: "CORNEAL ULCER",
        Description81: "Corneal ulcer is infection of the cornea due to bacteria, fungus, virus or any other micro-organisms. If one notices a white spot on the black part of the eye along with pain, redness, loss of vision. This can be a serious problem and lead to significant loss of vision. Doctor consultation should be done at the earliest.",
        Title9: "CORNEAL OPACITY",
        Description91: "Corneal opacification is partial or complete whitening of the cornea following corneal infection or injury. It causes decrease in vision and also gives poor cosmetic appearance. If it not a very dense or thick, may be removed by laser. If very dense or thick, may need corneal transplantation surgery",
    },
    {
        id: 1,
        ConditionName: 'Glaucoma',
        ConditionImage: Glaucoma,
        Heading01: "Glaucoma is a condition characterized by increased pressure within the eye. It can have a hereditary component and may run in families. Early symptoms of glaucoma include seeing glare and halos around lights, eye strain, and headaches.",
        PointHeading01: "Here are a few important facts about glaucoma:",
        Point1_1: "1.	Glaucoma is often referred to as the 'Silent Killer' because it is frequently diagnosed at an advanced stage.",
        Point1_2: "2.	Vision loss caused by glaucoma can be permanent.",
        Point1_3: "3.	Glaucoma typically affects both eyes, although one eye may be more severely affected than the other.",
        Point1_4: "4.	Glaucoma can be effectively managed with the use of prescribed eye drops.",
        Point1_5: "5.	Some forms of glaucoma may require simple laser procedures as part of the treatment plan.",
        Title1: "Diagnosis of Glaucoma:",
        Description11:"To accurately diagnose glaucoma, a comprehensive eye examination is conducted by an eye doctor. Several tests are performed to determine the severity of the condition. At Eye Life, we perform a series of tests known as 'Glaucoma Workup' to confirm the presence of glaucoma and assess its severity. The results of these tests guide the treatment approach.",
        Description12:"For glaucoma treatment, it is crucial to begin treatment promptly based on the test results. By seeking treatment early and following the prescribed treatment plan, vision loss and further progression of glaucoma can be managed effectively.",
        Description13:"If you are looking for glaucoma treatment or glaucoma treatment in Khar Road, it is advisable to research and choose a reputable clinic or eye center. Consultation with experienced glaucoma specialists can provide personalized treatment options and ensure the best possible outcomes.",
        Description14:"Please note that this information is provided for general knowledge and should not replace professional medical advice. Consultation with an ophthalmologist or glaucoma specialist is necessary for individualized guidance regarding glaucoma treatment.",
    },
    {
        id: 2,
        ConditionName: 'Lasik',
        ConditionImage: LasikDustbin,
        Heading01: "LASIK is a technique for Laser Vision Correction or removal of glass number using laser.",
        Heading02: "LASIK is a technique used for laser vision correction or the reduction of refractive errors using laser technology. It involves the creation of a flap on the cornea using a blade, followed by laser treatment to reshape the cornea.",
        Heading03: "Femtosecond laser LASIK is an advanced and safer technique compared to the older blade-based method. In this procedure, a specialized laser called the Femtosecond laser is used to create the corneal flap before the laser treatment is performed. This technique offers improved precision and safety.",
        Heading04: "SMILE (Small Incision Lenticule Extraction) is the latest advancement in laser vision correction. Unlike LASIK, it does not require the creation of a corneal flap. This flapless procedure enhances the overall safety of the treatment and minimizes the weakening of corneal strength.",
        Heading05: "PRK (Photorefractive Keratectomy) or surface ablation is performed when the cornea is too thin to create a flap. It preserves the structural strength of the cornea while correcting refractive errors.",
        Heading06: "Before undergoing LASIK surgery, it is crucial to undergo a detailed eye check-up, including assessments of eye number, eye pressure, and retinal status. Additional tests like Corneal Tomography, which determines corneal shape, thickness, and other essential parameters, Dry Eye evaluation, and other tests are mandatory to ensure maximum safety and success following LASIK surgery.",
        Heading07: "It is important to note that the goal of Laser Vision Correction is to reduce dependence on glasses or contact lenses, rather than achieving a 'zero' eye number.",
        Heading08: "Complications in LASIK surgery are rare, occurring in less than 1% of cases. They may include overcorrection or undercorrection of vision, infection, ectasia (progressive corneal thinning), or haze. It is advisable to discuss these potential risks and complications with your doctor before proceeding with LASIK.",
        Heading09: "If you are considering LASIK treatment in Mumbai, it is recommended to research and choose a reputable clinic or eye center. Ensure that the chosen facility has experienced surgeons and a track record of successful LASIK procedures.", 
        Heading10: "Please remember that this information is provided for general knowledge and should not replace professional medical advice. Consultation with an ophthalmologist or eye specialist is necessary to receive personalized guidance regarding LASIK treatment.", 
    },
    {
        id: 3,
        ConditionName: 'Oculoplasty',
        ConditionImage: Oculoplasty,
        Heading01:"Oculoplasty is a specialized field of ophthalmology that focuses on the treatment of eyelid and periocular conditions. Some common oculoplasty diseases include:",
        Title1:"Ptosis:",
        Description11:"Ptosis refers to the drooping of the upper eyelid, where it appears lower than the other eye. It can be present since birth (congenital ptosis) or develop later in life due to injury or aging. Ptosis can cause functional and cosmetic concerns, and surgical treatment is often required to lift the eyelid to a normal position.",
        Title2:"Entropion/Ectropion: ",
        Description21:"Entropion is the inward turning of the eyelid, while ectropion is the outward turning of the eyelid. These conditions can cause irritation, redness, and tearing. Surgical correction is typically performed to restore the normal position of the eyelid and improve eye comfort.",
        Title3:"Proptosis: ",
        Description31:"Proptosis refers to the protrusion or bulging of one or both eyeballs. It is commonly associated with thyroid eye disease, orbital tumors, or other underlying conditions. Treatment approaches may involve medical management, surgery, or a combination of both, depending on the underlying cause and severity.",
        Title4:"Watering of Eyes: ",
        Description41:"Excessive tearing or watering of the eyes can occur due to tear outflow blockage or other underlying factors. Diagnosis involves a thorough evaluation by an oculoplasty specialist, which may include tests to assess tear drainage. Treatment options range from conservative measures to surgical interventions, depending on the cause and severity of the condition.",
        Title5:"Cosmetic Problems: ",
        Description51:"Oculoplasty also addresses various cosmetic concerns related to the eyes, such as eyelid warts, puffiness, abnormal pigmentation, xanthelasma (yellowish plaques on the eyelids), and sunken eyes. Treatment options vary depending on the specific cosmetic issue and may include surgical or non-surgical approaches.",
        lastPara01:"If you are searching for oculoplasty treatment in Khar Road or elsewhere, it is recommended to seek care from reputable clinics or eye centers specializing in oculoplasty procedures. Including the keywords 'oculoplasty treatment' in your search can help you find relevant information and providers.",
        lastPara02:"Please note that the information provided here is for educational purposes and should not replace professional medical advice. It is essential to consult with an oculoplasty specialist for personalized guidance and treatment. Oculoplasty Treatment In Khar Road Oculoplasty Treatment Oculoplasty Treatment Oculoplasty Treatment",
    },
    {
        id: 4,
        ConditionName: 'Paediatric Ophthalmology',
        ConditionImage: PedOphthalmolgy,
        Heading01: "Pediatric ophthalmology specializes in diagnosing and treating eye diseases in children. Here are common eye conditions that can affect children:",
        Title1:"Refractive Error/Glass Number: ",
        Description11:"Children often experience refractive errors like myopia (nearsightedness), hypermetropia (farsightedness), and astigmatism. If left untreated, these conditions can lead to lazy eye or amblyopia. Excessive close-up activities, such as prolonged TV watching or gadget use, can contribute to myopia progression. Encouraging outdoor play is recommended to reduce the risk. Research is ongoing on the use of eye drops for myopia treatment.",
        Title2:"Lazy Eye/Amblyopia:",
        Description21:"Lazy eye or amblyopia refers to poor vision in one or both eyes. It can result from untreated refractive errors, cataracts, squint, or other eye conditions. Regular use of glasses helps treat lazy eye caused by refractive errors. Some children may require eye patching and exercises. Early treatment is crucial to prevent permanent vision loss.",
        Title3:"Cataract in Children: ",
        Description31:"While rare, cataracts can occur in children at birth or shortly after. They can affect one or both eyes. Laser cataract surgery is the usual treatment, followed by wearing glasses and regular follow-up with an eye doctor.",
        Title4:"Corneal Opacity in Children: ",
        Description41:"Corneal opacity presents as a white spot on the cornea and may be present from birth or develop later. Causes include corneal infections, nutritional deficiencies, injuries, or maternal infections. Surgical interventions like corneal transplantation or optical iridectomy may be necessary if corneal opacity leads to lazy eye. Nutritional deficiencies, such as vitamin A deficiency, should be addressed promptly.",
        Title5:"Watering of Eyes in Children: ",
        Description51:"Watering or discharge from a child's eyes is often due to tear outflow blockage. Gentle massage can help treat this condition in very young children. Early consultation with an eye doctor is important for proper management.",
        lastPara01:"If you are seeking pediatric ophthalmology treatment in Khar Road or elsewhere, it is advisable to find reputable clinics or eye centers specializing in pediatric eye conditions. Including the keywords 'pediatric ophthalmology treatment' in your search can be helpful.",
        lastPara02:"Please note that this information is provided for educational purposes and should not replace professional medical advice. Consultation with a pediatric ophthalmologist is necessary for personalized guidance and treatment. Pediatric ophthalmology Treatment In Khar Road Pediatric ophthalmology Treatment Pediatric ophthalmology Treatment Pediatric ophthalmology Treatment",
    },
    {
        id: 5,
        ConditionName: 'Retina',
        ConditionImage: Retina,
        Heading01: "Retina is the light sensitive layer of the eye which is found at the back part of the eye. Detailed check-up of the retina is done after applying eye drops that dilate the pupil and allow the Retina specialist to see the retina.",
        Title1: "Diabetic Retinopathy :",
        Description11: "Diabetic Retinopathy is retinal disease due to uncontrolled and longstanding diabetes. It can start with mild blurring of vision in early cases to sudden loss of vision and blackout due to bleeding in the eye in advanced cases. Vision loss due to diabetic retinopathy can be treated with injections and lasers. Advanced cases may need surgery. Therefore, for all individuals who are suffering from diabetes, yearly retinal check-up by qualified Retina specialist after dilating the pupil is mandatory.",
        Title2: "AGE RELATED MACULAR DEGENERATION (ARMD)",
        Description21: "ARMD is damage to central part of the retina due to age related damage. It usually affects both eyes. Patient see a black spot in front of their eyes when they try to look at something. ARMD can be Dry or Wet Type.. Diagnosis of ARMD is usually confirmed by OCT test. ARMD can be treated with injections which can be administered easily by a qualified retina specialist.",
        Title3: "HYPERTENSIVE RETINOPATHY",
        Description31: "Hypertensive retinopathy is due to damage to channels supplying blood to the retina. It can progress slowly and cause sudden loss of vision due to haemorrhage. Eye check up with Retina specialist can help in diagnosing this condition early. Strict monitoring of blood pressure is also essential.",
        Title4: "RETINAL DETACHMENT",
        Description41: "Retinal detachment can cause sudden black out and patient may feel like a black curtain in front of their eye. Patients who have minus number in their glasses are more likely to develop Retinal Detachment. Retinal detachment is an emergency. Treatment is to undergo surgery by qualified Retina specialist.",
        Title5: "LATTICE DEGENERATION",
        Description51: "Lattice degeneration are weak spots in the retina which can tear easily and cause Retinal detachment. Lattice degeneration are more commonly seen in people with minus number glasses. Retina check up after dilatation can easily diagnose Lattice degeneration. Laser treatment of the weak spot can help prevent Retinal detachment.",
        Title6: "ENDOPHTHALMITIS",
        Description61: "Infection of the retina is called Endophthalmitis. This is usually due to germs like bacteria or fungus entering the eye into the retina. Endophthalmitis is a serious problem and need immediate treatment with injections or surgery.",


        Title1: "Diabetic Retinopathy :",
        Description11: "Diabetic Retinopathy is a retinal disease that occurs as a result of uncontrolled and long-standing diabetes. It can manifest as mild blurring of vision in the early stages and progress to sudden vision loss and blackouts caused by bleeding in the eye in advanced cases. Injections and laser treatments can be used to manage vision loss due to diabetic retinopathy, while advanced cases may require surgery. It is mandatory for individuals with diabetes to undergo yearly retinal check-ups by a qualified retina specialist after pupil dilation.",
        Title2: "Age-Related Macular Degeneration (ARMD):",
        Description21: "ARMD refers to damage to the central part of the retina due to age-related factors. It typically affects both eyes, and patients may experience a black spot in their central vision. ARMD can be categorized as Dry or Wet Type, and its diagnosis is usually confirmed through an OCT (optical coherence tomography) test. Injections administered by a qualified retina specialist are commonly used to treat ARMD.",
        Title3: "Hypertensive Retinopathy:",
        Description31: "Hypertensive retinopathy occurs due to damage to the blood vessels supplying the retina. It can progress slowly and, in some cases, cause sudden vision loss due to hemorrhage. Regular eye check-ups with a retina specialist can aid in early diagnosis of this condition, while strict monitoring of blood pressure is also essential.",
        Title4: "Retinal Detachment:",
        Description41: "Retinal detachment can result in sudden vision loss, with patients describing a curtain-like black out in their visual field. People with nearsightedness (minus number glasses) are more prone to developing retinal detachment. Retinal detachment is considered an emergency and requires surgical treatment by a qualified retina specialist.",
        Title5: "Lattice Degeneration:",
        Description51: "Lattice degeneration refers to weak spots in the retina that can easily tear and lead to retinal detachment. It is more commonly observed in individuals with nearsightedness. Dilated retina check-ups can easily diagnose lattice degeneration, and laser treatment of the weak spots can help prevent retinal detachment.",
        lastPara01: "For retina treatment in Mumbai or elsewhere, it is important to seek the services of a reputable retina specialist or clinic. By conducting thorough research, one can find qualified professionals experienced in treating various retina conditions.",
        lastPara02: "Please note that the information provided is for general knowledge and should not replace professional medical advice. Consultation with a retina specialist or ophthalmologist is necessary for personalized guidance regarding retina treatment.",
    },
];

export default ConditionData;
