import React, { useState } from 'react';
import './conditions.css';
import conditionImage from '../../images/Diabetic-Retinopathy.png';
import ConditionData from './ConditionData';
import { useLocation } from 'react-router-dom';

const Conditions = () => {

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    console.log(id);

    const [edata, setEdata] = useState(ConditionData)
    const EventFdata = edata.find(e => e.id == id)

    return (
        <div>


            {/* conditions Home */}
            <div className='conditionsHome'>
                <h2>{EventFdata.ConditionName}</h2>
            </div>



            {/* condition details*/}
            <div className='conditionSection'>
                <div className='conditionText'>
                    <div className='conditionImage-con'>
                        <div className='conditionImage'>
                            <img src={EventFdata.ConditionImage} alt="" />
                        </div>
                    </div>
                    <p>{EventFdata.Heading01}</p>
                    <p>{EventFdata.Heading02}</p>
                    <p>{EventFdata.Heading03}</p>
                    <p>{EventFdata.Heading04}</p>
                    <p>{EventFdata.Heading05}</p>
                </div>
                {/* <div className='col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='conditionImg'>
                            <img src={conditionImage} alt="" />
                        </div>
                    </div> */}
            </div>

            <div className='Point-Con'>
                <h4>{EventFdata.PointHeading01}</h4>
                <p>{EventFdata.Point1_1}</p>
                <p>{EventFdata.Point1_2}</p>
                <p>{EventFdata.Point1_3}</p>
                <p>{EventFdata.Point1_4}</p>
                <p>{EventFdata.Point1_5}</p>
            </div>

            {/* condition questions */}
            <div className='conditionQuestions'>
                <div className='row'>
                    <div className='col-md-10'>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title1}</h5>
                            <p>{EventFdata.Description11}</p>
                            <p>{EventFdata.Description12}</p>
                            <p>{EventFdata.Description13}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title2}</h5>
                            <p>{EventFdata.Description21}</p>
                            <p>{EventFdata.Description22}</p>
                            <p>{EventFdata.Description23}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title3}</h5>
                            <p>{EventFdata.Description31}</p>
                            <p>{EventFdata.Description32}</p>
                            <p>{EventFdata.Description33}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title4}</h5>
                            <p>{EventFdata.Description41}</p>
                            <p>{EventFdata.Description42}</p>
                            <p>{EventFdata.Description43}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title5}</h5>
                            <p>{EventFdata.Description51}</p>
                            <p>{EventFdata.Description52}</p>
                            <p>{EventFdata.Description53}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title6}</h5>
                            <p>{EventFdata.Description61}</p>
                            <p>{EventFdata.Description62}</p>
                            <p>{EventFdata.Description63}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title7}</h5>
                            <p>{EventFdata.Description71}</p>
                            <p>{EventFdata.Description72}</p>
                            <p>{EventFdata.Description73}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title8}</h5>
                            <p>{EventFdata.Description81}</p>
                            <p>{EventFdata.Description82}</p>
                            <p>{EventFdata.Description83}</p>
                        </div>
                        <div className='conditionQuestionsText'>
                            <h5>{EventFdata.Title9}</h5>
                            <p>{EventFdata.Description91}</p>
                            <p>{EventFdata.Description92}</p>
                            <p>{EventFdata.Description93}</p>
                        </div>
                    </div>
                    <div className='col-md-2'></div>
                </div>
            </div>

            <div className='Condition-Last-Para'>
                <div className='container'>
                    <p>
                        {
                            EventFdata.lastPara01
                        }
                    </p>
                    <p>
                        {
                            EventFdata.lastPara02
                        }
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Conditions;