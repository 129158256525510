import React from "react";
import "./reviewCard.css";

const reviewCard = (props) => {
  return (
    <div>
      <div className="reviewCard">
        <div className="reviewQuote">
          <i class="fa fa-quote-left" aria-hidden="true"></i>
        </div>
        <h6>{props.testimonalText}</h6>
        <div className="reviewNameImg">
          <div className="reviewImg">
            <img src={props.testimonalImg} alt="" />
          </div>
          <div className="reviewNameStar">
            <h4>{props.testimonalName}</h4>
            <div className="reviewStars">
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reviewCard;
