import React, { useEffect} from 'react';
import { useState } from 'react';
import './GoToTop.css';

const GoToTop = () => {

    const [isVisible, setIsVisible] = useState(false);

    const goToBtn = () => {
        window.scrollTo({top:0, left:0, behavior:'smooth'});
    }

    const listenToScroll = () => {

        let heightToHidden = 250;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop ;

        if(winScroll > heightToHidden) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        } 
    }; 

    useEffect( () => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

  return (
    <div className='topBtnSection' onClick={goToBtn}>
            {isVisible && (
        <div className='top-btn'>
            <div className="topIcon">
            <i class="fa fa-angle-double-up" aria-hidden="true"></i>
            </div>
        </div>
        )}
    </div>
  )
}

export default GoToTop;